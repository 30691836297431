<template>
  <v-form ref="form" @submit.prevent>
    <v-card
      :max-width="cardSize.maxWidth"
      :width="cardSize.width"
      :class="cardSize.class"
    >
      <v-card-title>
        <h1 class="text-h5 text-sm-h4 white-space">Koda pieslēgšanās</h1>
      </v-card-title>
      <v-card-subtitle class="text-subtitle-2 text-sm-subtitle-1 white-space"
        >Ja jums tika izsniegts piekļuves kods, šeit jūs varat ar to
        pieslēgties.</v-card-subtitle
      >
      <v-card-text class="my-1">
        <v-text-field
          v-model="code"
          :rules="rules.code"
          variant="underlined"
          color="primary"
          label="Kods"
          required
          autocomplete="current-code"
          placeholder="Ievadīt kodu"
          class="mb-3"
        ></v-text-field>
      </v-card-text>
      <v-alert v-if="error" type="error" class="mt-3">
        {{ error }}
      </v-alert>
      <v-card-actions class="d-flex flex-column">
        <v-btn
          block
          class="mx-0 my-1 py-5"
          variant="flat"
          color="primary"
          @click="handleLoginClick"
        >
          Pieslēgties
        </v-btn>
        <p class="mt-8">
          Piekļūt bez koda?
          <span class="fake-a text-blue" @click="goTo('login')"
            >Pieslēdzies</span
          >
        </p>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { AuthAPI } from "@/api/apis/authActions";
import { updateTempHeaders } from "@/api/config";

export default {
  name: "LoginCodeView",
  inject: ["cardSize"],
  data: () => {
    return {
      code: "",
      error: "",
      rules: {
        code: [
          (v) => !!v || "Kods ir obligāts",
          (v) => (v && v.length === 15) || "Koda garumam jābūt 15 simboliem",
        ],
      },
      isLoading: false,
    };
  },
  methods: {
    goTo(route) {
      this.$router.push({ name: route });
    },
    async handleLoginClick() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) return;
      this.isLoading = true;
      AuthAPI.loginWithCode(this.code)
        .then((data) => {
          localStorage.setItem("temp-token", this.code);
          updateTempHeaders(this.code);
          this.$router.push(`/course/${data.data.course.id}`);
        })
        .catch((e) => {
          this.error = e.message;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.fake-a {
  cursor: pointer;
}

.fake-a:hover {
  color: #1976d2;
}
</style>
