<template>
  <div class="content">
    <HeaderSection />
    <OfferSection />
    <div class="w-100">
      <div class="d-flex flex-column align-center">
        <h2 class="section-title">Ieplānoto apmācību kalendārs</h2>
        <p>Apskaties mūsu apmācību kalendāru ar visām ieplānotām apmācībām</p>
      </div>
      <EventsCalendar />
    </div>
    <CoursesSection />
    <LargeNavigationButtonsSection />
  </div>
</template>

<script>
import HeaderSection from "@/components/HomePage/HeaderSection.vue";
import OfferSection from "@/components/HomePage/OfferSection.vue";
import CoursesSection from "@/components/HomePage/CoursesSection.vue";
import LargeNavigationButtonsSection from "@/components/HomePage/LargeNavigationButtonsSection.vue";
import EventsCalendar from "@/components/EventsCalendar.vue";

export default {
  name: "HomeView",
  components: {
    EventsCalendar,
    LargeNavigationButtonsSection,
    CoursesSection,
    OfferSection,
    HeaderSection,
  },
};
</script>

<style scoped>
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 100px;
}
</style>
