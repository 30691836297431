<template>
  <v-form ref="form" @submit.prevent>
    <v-card
      :max-width="cardSize.maxWidth"
      :width="cardSize.width"
      :class="cardSize.class"
    >
      <v-card-title>
        <h1 class="text-h5 text-sm-h4 white-space">Esi sveicināts!</h1>
      </v-card-title>
      <v-card-subtitle class="text-subtitle-2 text-sm-subtitle-1 white-space"
        >Lūdzu ievadiet savus datus</v-card-subtitle
      >
      <v-card-text class="my-1">
        <v-text-field
          v-model="email"
          :rules="rules.email"
          variant="underlined"
          color="primary"
          label="E-pasts"
          autocomplete="email"
          required
          class="mb-3"
        ></v-text-field>
        <v-text-field
          v-model="password"
          :rules="rules.password"
          variant="underlined"
          color="primary"
          label="Parole"
          required
          prepend-inner-icon="mdi-lock-outline"
          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'"
          class="mb-1"
          autocomplete="current-password"
          @click:append-inner="visible = !visible"
        ></v-text-field>
        <div class="d-flex flex-row-reverse">
          <p class="text-right fake-a" @click="goTo('reset-password')">
            Aizmirsi paroli?
          </p>
        </div>
        <v-alert v-if="error" type="error" class="mt-3">
          {{ error }}
        </v-alert>
      </v-card-text>
      <v-card-actions class="d-flex flex-column">
        <v-btn
          block
          class="mx-0 my-1 py-5"
          variant="flat"
          color="primary"
          type="submit"
          :disabled="isLoading"
          :loading="isLoading"
          @click="submitLogin"
          >Pieslēgties</v-btn
        >
        <v-btn
          block
          class="mx-0 my-1 py-5"
          variant="outlined"
          color="primary"
          @click="goTo('login-code')"
          >Ievadīt kodu</v-btn
        >
        <p class="mt-8">
          Nav konta?
          <span class="fake-a text-blue" @click="goTo('register')"
            >Reģistrēties</span
          >
        </p>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { ruleSetGen, ruleSet } from "@/helpers/rules";
import { AuthAPI } from "@/api/apis/authActions";
import { log } from "@/helpers/debugLog";
import { UserData } from "@/storage/user";
import { updateHeaders } from "@/api/config";
export default {
  name: "LoginView",
  inject: ["cardSize"],
  data: () => {
    return {
      visible: false,
      email: "",
      password: "",
      rules: {
        email: ruleSet.email,
        password: ruleSetGen.text("Lūdzu ievadiet derīgu paroli", true, 8),
      },
      error: "",
      isLoading: false,
    };
  },
  created() {
    if (UserData.IsLoggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    goTo(route) {
      this.$router.push({ name: route });
    },
    async submitLogin() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) return;
      this.isLoading = true;
      AuthAPI.login(this.email, this.password)
        .then((res) => {
          log("LoginView", res, 6);
          if (res.code === 403) {
            window.location.replace(
              `/auth/confirm-email?id=${
                res.response.data.user_id
              }&email=${encodeURIComponent(res.response.data.email)}`,
            );
          } else if (!res.ok) {
            const response = res.response;
            let errStr = response.message ?? "";
            for (const key in response.errors) {
              errStr += response.errors[key][0] + "\n";
            }
            this.error = errStr;
          } else {
            const response = res.response;
            UserData.setUser(response.data);
            localStorage.setItem("token", response.access_token);
            updateHeaders(response.access_token);
            this.goTo("home");
          }
        })
        .catch(() => {
          this.error = "Servera kļūda, lūdzu mēģināt vēlāk";
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.fake-a {
  cursor: pointer;
}

.fake-a:hover {
  color: #1976d2;
}
</style>
