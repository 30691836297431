<template>
  <v-main class="d-flex flex-column justify-space-between">
    <MainNavigationBar />
    <error-dialog />
    <v-container
      :class="`fill-local-height diagonal-split-background ${
        $vuetify.display.xs ? 'px-0' : ''
      }`"
      fluid
    >
      <v-layout class="align-center justify-center d-flex">
        <slot />
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { computed } from "vue";
import MainNavigationBar from "@/components/MainNavigationBar.vue";
import ErrorDialog from "@/components/ErrorDialog.vue";
export default {
  name: "AuthLayout",
  components: { MainNavigationBar, ErrorDialog },
  provide() {
    return {
      cardSize: computed(() => {
        if (!this.$vuetify.display.xs) {
          return {
            maxWidth: "70vw",
            width: "400px",
            class: "py-5 px-5 rounded-lg",
          };
        } else {
          return {
            class: "py-5 rounded-lg full-width-card",
          };
        }
      }),
    };
  },
};
</script>
<style scoped>
.fill-local-height {
  margin-top: 76px;
  height: calc(100vh - 76px);
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.diagonal-split-background::before {
  content: "";
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(80vh - 76px);
  background-color: #fff;
  background-image: linear-gradient(-5deg, #9dd9fe 50%, #fff 50.15%);
}
</style>
