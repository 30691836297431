<template>
  <MainNavigationBar />

  <div
    v-if="quizSuccessful !== null"
    :class="`container success-${quizSuccessful}`"
  >
    <div class="header">
      <span v-if="quizSuccessful">Tests nokārtots veiksmīgi</span>
      <span v-else>Tests netika nokārtots</span>
      <div class="result">
        <v-btn icon="true" size="32" variant="flat">
          <v-icon size="32">mdi-information-outline</v-icon>
          <v-tooltip activator="parent" location="start"
            >Lai tests būtu ieskaitīts, pareizi jāatbild vismaz 50%
            jautājumi</v-tooltip
          >
        </v-btn>
        <div class="score-container">
          <span>{{ correctQuestions }}/{{ totalQuestions }}</span>
          <span class="percentage">&nbsp;({{ scoreString }})</span>
        </div>
      </div>
    </div>
    <div v-if="quizSuccessful" class="card-content">
      <p>Jūs esat veiksmīgi nokārtojis testu.</p>
      <p v-if="isLicenseCard">
        Rezultāts ir saglabāts, apliecība tiks nogādāta uz Jūsu norādīto, kursa
        pasūtīšanas laikā, pakomātu.
      </p>
      <p v-else>
        Rezultāts ir saglabāts, un jūs varat lejupielādēt jūsu sertifikātu.
      </p>
    </div>
    <div v-else class="card-content">
      <p>Jūs neēsat nokārtojis testu.</p>
      <p>
        Droši varat mēģināt pildīt testu vēlreiz. Pārliecinaties ka pārzinat
        tematu, un mēģiniet atkal.
      </p>
    </div>
    <div class="btn-actions">
      <v-btn
        v-if="quizSuccessful && certificateDownloadUrl && !isLicenseCard"
        color="transparent"
        variant="flat"
        class="text-lowercase prev-btn"
        @click="downloadCertificate()"
      >
        <v-icon class="mr-3">mdi-download</v-icon>
        Lejupielādēt sertifikātu
      </v-btn>
      <v-btn
        color="transparent"
        variant="flat"
        class="text-lowercase prev-btn"
        @click="seeQuizResults()"
      >
        Skatīt savas kļūdas
      </v-btn>
      <v-btn
        v-if="UserData.IsCompanyUser && quizSuccessful"
        color="primary"
        variant="flat"
        class="text-lowercase"
        @click="logout()"
      >
        Atslēgties</v-btn
      >
      <v-btn
        v-else
        color="primary"
        variant="flat"
        class="text-lowercase"
        @click="goBackToCourse()"
      >
        Atgriezties uz kursu</v-btn
      >
    </div>
  </div>
  <div v-else class="spinner-container d-flex justify-center align-center">
    <v-progress-circular
      indeterminate
      size="64"
      color="primary"
    ></v-progress-circular>
  </div>
</template>

<script>
import config from "@/api/config";
import MainNavigationBar from "@/components/MainNavigationBar.vue";
import { UserData } from "@/storage/user";

export default {
  name: "QuizOverview",
  components: {
    MainNavigationBar,
  },
  data() {
    return {
      quizSuccessful: null,
      certificateDownloadUrl: null,
      isLicenseCard: null,
      totalQuestions: 0,
      correctQuestions: 0,
      scoreString: 0,
    };
  },
  computed: {
    UserData() {
      return UserData;
    },
  },
  mounted() {
    this.getQuizStatus();
  },
  methods: {
    async getQuizStatus() {
      if (!UserData.IsCompanyUser) {
        await fetch(`${config.baseURL}/user_course/${this.$route.params.id}`, {
          method: "GET",
          headers: config.headers,
        })
          .then((response) => {
            if (!response.ok) throw new Error("Something went wrong.");
            return response.json();
          })
          .then((response) => {
            if (response.data.order_completed === false)
              throw new Error("You don't yet have access to this course.");
            this.quizSuccessful = response.data.is_completed;
            this.certificateDownloadUrl = response.data.certificate_url;
            this.correctQuestions = response.data.correct_answers;
            this.totalQuestions = response.data.test_question_count;
            this.scoreString = response.data.score_string;
            this.isLicenseCard = response.data.course.is_license_card;
          })
          .catch(() => {
            this.$router.push({
              name: "course",
              params: {
                id: this.$route.params.id,
              },
            });
          });
      } else {
        await fetch(`${config.baseURL}/company_user`, {
          method: "GET",
          headers: config.headers,
        })
          .then((response) => {
            if (!response.ok) throw new Error("Something went wrong.");
            return response.json();
          })
          .then((response) => {
            if (response.data.order_completed === false)
              throw new Error("You don't yet have access to this course.");
            this.quizSuccessful = response.data.is_completed;
            this.certificateDownloadUrl = false;
            this.correctQuestions = response.data.correct_answers;
            this.totalQuestions = response.data.test_question_count;
            this.scoreString = response.data.score_string;
          })
          .catch(() => {
            this.$router.push({
              name: "course",
              params: {
                id: this.$route.params.id,
              },
            });
          });
      }
    },
    seeQuizResults() {
      this.$router.push({
        name: "test-result",
        params: { id: this.$route.params.id },
      });
    },
    goBackToCourse() {
      this.$router.push({
        name: "course",
        params: { id: this.$route.params.id },
      });
    },
    downloadCertificate() {
      if (!this.certificateDownloadUrl) return;

      window.open(this.certificateDownloadUrl, "_blank");
    },
    logout() {
      UserData.wipeData();
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 100px;
  margin-top: 120px;
  max-width: 1000px;
  padding: 15px 20px;
  margin-inline: auto;
  border-radius: 10px;
  transition: opacity 0.2s ease;
  background-color: white;
}

.container.success-true {
  box-shadow: 0 16px 64px rgb(143 255 128 / 30%);
}

.container.success-false {
  box-shadow: 0 16px 64px rgba(255, 128, 128, 30%);
}

.score-container {
  text-wrap: nowarp;
}

.score-container .percentage {
  font-size: 18px;
  color: #7e7e7e;
}

.spinner-container {
  height: 100vh;
}

.btn-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}

.container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 28px;
}

.result {
  display: flex;
  gap: 10px;
  align-items: center;
}

.card-content {
  width: 100%;
  font-size: 18px;
}

@media screen and (width <= 600px) {
  .container {
    padding: 15px 10px;
  }

  .header {
    flex-direction: column;
    gap: 10px;
  }

  .header > span {
    text-align: center;
  }

  .btn-actions {
    flex-direction: column;
    gap: 10px;
  }
}
</style>
