<template>
  <div class="container">
    <div class="header-container">
      <h1 class="header-title">Mācību centrs</h1>
    </div>
    <v-expansion-panels>
      <v-expansion-panel>
        <template #title>
          <h3>Krūmgrieža, motorzāģa operators</h3>
        </template>
        <template #text>
          <p>
            02.05.2012. MK noteikumu Nr.310 “Darba aizsardzības prasības
            mežsaimniecībā” 3. pants nosaka, ka persona mežsaimniecības darbos
            drīkst lietot motorinstrumentus (krūmgriezi vai motorzāģi) tikai
            tad, ja saņēmusi attiecīgā motorinstrumenta operatora kvalifikāciju
            apliecinošu licencētas izglītības iestādes izsniegtu apliecību.
            Apmācībās gūtās zināšanas pasargās nodarbinātos no nelaimes
            gadījumiem darba vietā, kā arī nodrošinās iemaņas kvalitatīvi un
            dinamiski veikt sev uzticētos darba pienākumus ar krūmgriezi un
            motorzāģi, ceļot darba produktivitāti.<br /><br />
            <b>Mācību ilgums:</b> 6 stundas (klātienē/attālināti/e-vide) vai 13
            stundas (ar praktiskām nodarbībām)<br />
            <b>Iegūstamais dokuments:</b> Apliecība
          </p>
        </template>
      </v-expansion-panel>
      <v-expansion-panel>
        <template #title>
          <h3>Stropētājs</h3>
        </template>
        <template #text>
          <p>
            Stropētāja darba specifika prasa īpašu apmācību, jo tā darbs cieši
            saistīts ar dažādu kravu drošību, kas būtiski ietekmē kā uzņēmuma,
            tā arī darbinieka produktivitāti. Apmācību gaitā izglītojamais tiek
            iepazīstināts ar LR likumdošanā noteiktajām darba aizsardzības
            prasībām, lietojot akustiskos, roku u.c. signālus un zīmes,
            atbilstoši 03.09.2002. MK noteikumiem Nr.400 „Darba aizsardzības
            prasības drošības zīmju lietošanā”, kā arī kravas, celtņu un stropju
            veidiem. Gūtās zināšanas nodrošinās efektīvu darba izpildi un
            samazinās negadījumu iespējamību darba vietā.<br /><br />
            <b>Mācību ilgums:</b> 8 stundas (klātienē/attālināti/e-vide)vai 45
            stundas (ar praktiskām nodarbībām)<br />
            <b>Iegūstamais dokuments:</b> Apliecība
          </p>
        </template>
      </v-expansion-panel>
      <v-expansion-panel>
        <template #title>
          <h3>Pacēlāja mašīnists/operators</h3>
        </template>
        <template #text>
          <p>
            Apgūt prasmes un zināšanas, nostiprinot, pārvietojot kravas ar
            stropju palīdzību.<br /><br />
            <b>Mācību ilgums:</b> 8 stundas (klātienē/attālināti/e-vide) vai 45
            stundas (ar praktiskām nodarbībām)<br />
            <b>Iegūstamais dokuments:</b> Apliecība
          </p>
        </template>
      </v-expansion-panel>
      <v-expansion-panel>
        <template #title>
          <h3>Apkures/krāšņu kurinātājs</h3>
        </template>
        <template #text>
          <p>
            Programmas mērķis: Apgūt prasmes uzraudzīt tvaika turbīnas;
            virszemes un virsūdens boilerus un dzinējus, ko kurina ar cieto
            kurināmo, ievērojot āra gaisa temperatūras grafiku.<br /><br />
            <b>Mācību ilgums:</b> 8 stundas(klātienē/attālināti/e-vide) vai 40
            stundas (ar praktiskām nodarbībām)<br />
            <b>Iegūstamais dokuments:</b> Apliecība
          </p>
        </template>
      </v-expansion-panel>
      <v-expansion-panel>
        <template #title>
          <h3>Zemspiediena katliekārtu operators</h3>
        </template>
        <template #text>
          <p>
            Zemspiediena katlu operators var strādāt katlu mājās, veikt apkures
            darbus, sagatavot katlu darbam, veikt pārbaudi un darbības kontroli,
            ievērojot darba drošības un ugunsdrošības noteikumus. Saskaņā ar
            likumu “Par bīstamo iekārtu tehnisko uzraudzību” zemspiediena katlu
            iekārtas klasificējamas kā bīstamās iekārtas, kas neatbilstošas
            lietošanas un uzturēšanas rezultātā var apdraudēt cilvēku dzīvību un
            veselību, vidi un materiālās vērtības. Augstāk minētā likuma 14.
            panta 3. punkts nosaka, ka zemspiediena katlu iekārtas īpašnieka
            pienākums ir nodrošināt zemspiediena iekārtas apkalpojošā personāla
            apmācību un instruktāžas, kas paredzētas normatīvajos aktos par
            darba aizsardzību, ņemot vērā bīstamās iekārtas veidu, sarežģītības
            un bīstamības pakāpi, kā arī darbinieku izglītību un iepriekšējā
            darba pieredzi. 16.02.2010. MK noteikumu Nr.138 “Noteikumi par
            katliekārtu tehnisko uzraudzību” 15. punkta 3. pants nosaka, ka
            iekārtas atbildīgā speciālista pienākums ir uzraudzīt, lai
            apkalpojošā personāla kvalifikācija atbilstu noteiktajām
            prasībām.<br /><br />
            <b>Mācību ilgums:</b> 6 stundas (klātienē/attālināti/e-vide)<br />
            <b>Iegūstamais dokuments:</b> Apliecība
          </p>
        </template>
      </v-expansion-panel>
      <v-expansion-panel>
        <template #title>
          <h3>Darbs augstumā</h3>
        </template>
        <template #text>
          <p>
            Saskaņā ar MK noteikumiem Nr.143 „Darba aizsardzības prasības,
            strādājot augstumā” (pieņemti 18.03.2014.) darbu augstumā saprot kā
            strādāšanu vietās, kas atrodas vismaz 1,5 m augstumā no zemes vai
            jebkuras citas drošas virsmas, tai skaitā darbs pazemē, ja ir
            iespējams kritiens, uz sastatnēm, kāpnēm, jumtiem, kā arī izmantojot
            virves un stiprinājumus. MK noteikumi nosaka, ja darbs augstumā rada
            paaugstinātu risku nodarbināto drošībai un veselībai, darbu
            veikšanai darba devējs norīko vismaz divus nodarbinātos, kuri ir
            praktiski apmācīti drošai darbu veikšanai, darba aprīkojuma un
            individuālo līdzekļu lietošanai darbam augstumā.<br /><br />
            <b>Mācību ilgums:</b> 6 stundas (klātienē/attālināti/e-vide) vai 18;
            24 vai 32 stundas (ar praktiskām nodarbībām)<br />
            <b>Iegūstamais dokuments:</b> Apliecība
          </p>
        </template>
      </v-expansion-panel>
      <v-expansion-panel>
        <template #title>
          <h3>Autoiekrāvēja vadītājs</h3>
        </template>
        <template #text>
          <p>
            <b>Izglītības programmas mērķis:</b> sniegt zināšanas un prasmes
            izvēlēties nepieciešamās iekārtas, ierīces, palīgierīces,
            instrumentus atbilstoši pārvietojamās kravas gabarītiem, svaram,
            veidam; ievērot MK noteikumu prasības; veikt autoiekrāvēja tehnisko
            apkopi; ievērot kravu celšanas, pārvietošanas un nokraušanas
            kārtību; ievērot transporta līdzekļa iekraušanas un izkraušanas
            kārtību; pārbaudīt kravas satvērējierīces; strādāt ar
            celtspējastabulām un ievērot darba drošības noteikumus.<br /><br />
            <b>Mācību ilgums:</b> 8 stundas (klātienē/attālināti/e-vide) vai 18
            stundas (ar praktiskām nodarbībām)<br />
            <b>Iegūstamais dokuments:</b> Apliecība
          </p>
        </template>
      </v-expansion-panel>
      <v-expansion-panel>
        <template #title>
          <h3>Elektroiekrāvēja vadītājs</h3>
        </template>
        <template #text>
          <p>
            <b>Izglītības programmas mērķis:</b> sniegt zināšanas un prasmes
            izvēlēties nepieciešamās iekārtas, ierīces, palīgierīces,
            instrumentus atbilstoši pārvietojamās kravas gabarītiem, svaram,
            veidam; ievērot MK noteikumu prasības; veikt elektroiekrāvēja
            tehnisko apkopi; ievērot kravu celšanas, pārvietošanas un
            nokraušanas kārtību; ievērot transporta līdzekļa iekraušanas un
            izkraušanas kārtību; pārbaudīt kravas satvērējierīces; strādāt ar
            celtspējastabulām un ievērot darba drošības noteikumus.<br /><br />
            <b>Mācību ilgums:</b> 8 stundas (klātienē/attālināti/e-vide) vai 18
            stundas (ar praktiskām nodarbībām)<br />
            <b>Iegūstamais dokuments:</b> Apliecība
          </p>
        </template>
      </v-expansion-panel>
      <v-expansion-panel>
        <template #title>
          <h3>
            Atbildīgais speciālists par kravas celtņa tehnisko stāvokli, tā
            drošu lietošanu un apkopi
          </h3>
        </template>
        <template #text>
          <p>
            MK noteikumi Nr.341 (pieņemti 14.06.2022.) ,,,Kravas celtņu drošības
            un tehniskās uzraudzības noteikumi”’ nosaka, ka kravas celtņa
            valdītājam ir pienākumus ar rakstisku rīkojumu norīkot atbilstošas
            kvalifikācijas speciālistu (turpmāk – atbildīgais speciālists), kas
            ir atbildīgs par kravas celtņa tehnisko stāvokli, tā drošu lietošanu
            un apkopi.<br /><br />
            <b>Mācību ilgums:</b> 4 stundas(attālināti/e-vide)<br />
            <b>Iegūstamais dokuments:</b> Apliecība
          </p>
        </template>
      </v-expansion-panel>
      <v-expansion-panel>
        <template #title>
          <h3>
            Atbildīgais speciālists par cilvēku celšanai paredzētā pacēlāja
            tehnisko stāvokli, tā drošu lietošanu un apkopi
          </h3>
        </template>
        <template #text>
          <p>
            MK noteikumi Nr. 137 „Cilvēku celšanai paredzēto pacēlāju tehniskās
            uzraudzības kārtība” (pieņemti 16.02.2010.) nosaka, ka pacēlāja
            valdītājam ir pienākums ar rakstisku rīkojumu norīkot atbilstošas
            kvalifikācijas speciālistu, kas ir atbildīgs par pacēlāja tehnisko
            stāvokli, tā drošu lietošanu un apkopi;<br /><br />
            <b>Mācību ilgums:</b> 4 stundas(attālināti/e-vide)<br />
            <b>Iegūstamais dokuments:</b> Apliecība
          </p>
        </template>
      </v-expansion-panel>
      <v-expansion-panel>
        <template #title>
          <h3>Minimālās higiēnas prasības pārtikas uzņēmumos</h3>
        </template>
        <template #text>
          <p>
            Saskaņā ar29.09.2015. MK noteikumiem Nr.545 "Pārtikas apritē
            nodarbināto personu apmācības kārtība pārtikas higiēnas jomā”
            <b>Mērķauditorija:</b>
            1 - Darbinieki, kas uzsāk darbu pārtikas apritē, bet nav ieguvuši
            profesionālo kvalifikāciju nevienā pārtikas nozares specialitātē; 2
            – Darbinieki, kas ir ieguvuši profesionālo kvalifikāciju kādā
            pārtikas nozares specialitātē, bet vairāk nekā trīs gadus nav bijuši
            nodarbināti pārtikas apritē.<br /><br />
            <b>Mācību ilgums:</b> 4 stundas(attālināti/e-vide)<br />
            <b>Iegūstamais dokuments:</b> Apliecība
          </p>
        </template>
      </v-expansion-panel>
      <v-expansion-panel>
        <template #title>
          <h3>
            Paškontroles sistēmas (HACCP) izveides pamatprincipi pārtikas
            aprites uzņēmumos
          </h3>
        </template>
        <template #text>
          <p>
            Ēdināšanas uzņēmumā viens darbinieks apgūst šo apmācību<br /><br />
            <b>Mācību ilgums:</b> 12 stundas(klātiene/attālināti/e-vide)<br />
            <b>Iegūstamais dokuments:</b> Apliecība
          </p>
        </template>
      </v-expansion-panel>
      <v-expansion-panel>
        <template #title>
          <h3>Uzticības personas un darba aizsardzības koordinatori</h3>
        </template>
        <template #text>
          <p>
            Nepieciešamās zināšanas darba aizsardzības jautājumos saskaņā ar
            10.08.2010 MK noteikumiem Nr. 749 ,,Apmācības kārtība darba
            aizsardzības jautājumos”<br /><br />
            <b>Mācību ilgums:</b> 40 stundas (klātiene/attālināti/e-vide)<br />
            <b>Iegūstamais dokuments:</b> Apliecība
          </p>
        </template>
      </v-expansion-panel>
      <v-expansion-panel>
        <template #title>
          <h3>Izglītojamo audzināšanas vadlīnijas</h3>
        </template>
        <template #text>
          <p>
            Mācību kursu programma izglītības iestāžu pedagogiem par darbu ar
            izglītojamiem - Audzināšanas mērķis, uzdevumi un izkopjamie tikumi;
            Audzināšanas mērķa un uzdevumu īstenošana izglītības iestādē; Valsts
            svētki, atceres un atzīmējamās dienas un valsts simbolu (valsts
            karoga, valsts himnas, valsts ģerboņa) lietošana izglītības iestādē;
            Kārtība, kādā izvērtējama informācijas, mācību līdzekļu, materiālu
            un mācību un audzināšanas metožu atbilstība izglītojamā tikumiskās
            attīstības nodrošināšanai, un izvērtēšanas kritēriji<br /><br />
            <b>Mācību ilgums:</b> 6 stundas (klātiene/attālināti)<br />
            <b>Iegūstamais dokuments:</b> Apliecība
          </p>
        </template>
      </v-expansion-panel>
      <v-expansion-panel>
        <template #title>
          <h3>Bērnu tiesību aizsardzība</h3>
        </template>
        <template #text>
          <p>
            Mācību kursa programma (saskaņota ar Valsts bērnu tiesību
            aizsardzības inspekciju) ir izstrādāta saskaņā ar Bērnu tiesību
            aizsardzības likuma 5.1 panta otro daļu un prasībām MK noteikumos
            Nr. 173 (01.04.2014.) „Noteikumi par kārtību, kādā apgūst speciālās
            zināšanas bērnu tiesību aizsardzības jomā, šo zināšanu saturu un
            apjomu”: Noteikumi nosaka kārtību, kādā valsts un pašvaldību
            institūciju speciālisti, kuri izskata lietas, kas saistītas ar bērnu
            tiesību aizsardzību (turpmāk - speciālists), apgūst speciālas
            zināšanas bērnu tiesību aizsardzības jomā, un šo zināšanu saturu.<br /><br />
            <b>Mācību ilgums:</b> 8 stundas (klātiene/attālināti)<br />
            <b>Iegūstamais dokuments:</b> Apliecība
          </p>
        </template>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: "LearningCenterView",
};
</script>

<style scoped>
.container {
  width: 100%;
  margin-top: 150px;
}

b,
h2,
h3 {
  color: #188ed7;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title {
  flex: 1;
  font-size: 56px;
}

.header-image {
  flex: 1;
  height: 100px;
}

.list {
  margin-left: 30px;
}

.nested-list {
  margin-left: 50px;
}

.divider {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media only screen and (width <= 900px) {
  .header-title {
    font-size: 36px;
    text-align: center;
  }

  .header-image {
    display: none;
  }
}
</style>
