<template>
  <v-form ref="form" @submit.prevent>
    <v-card
      :max-width="cardSize.maxWidth"
      :width="cardSize.width"
      :class="cardSize.class"
    >
      <v-card-title>
        <h1 class="text-h5 text-sm-h4 white-space">Aizmirsi paroli?</h1>
      </v-card-title>
      <v-card-subtitle class="text-subtitle-2 text-sm-subtitle-1 white-space"
        >Lūdzu ievadiet savus datus</v-card-subtitle
      >
      <v-card-text v-if="!sent" class="my-1">
        <v-text-field
          v-model="email"
          :rules="rules.email"
          variant="underlined"
          color="primary"
          label="E-pasts"
          placeholder="piemērs@inbox.lv"
          required
          class="mb-3"
        ></v-text-field>
        <v-alert v-if="error" type="error" class="mt-3">
          {{ error }}
        </v-alert>
      </v-card-text>
      <v-card-text v-else class="my-1">
        <v-alert type="success" class="mt-3">
          E-pasts ar paroles maiņas saiti ir nosūtīts!
        </v-alert>
      </v-card-text>
      <v-card-actions class="d-flex flex-column">
        <v-btn
          block
          class="mx-0 my-1 py-5"
          variant="flat"
          color="primary"
          type="submit"
          :disabled="isLoading || sent"
          :loading="isLoading"
          @click="submitReset"
          >Mainīt paroli</v-btn
        >
        <p class="mt-8">
          Jau esi reģistrējies?
          <span class="fake-a text-blue" @click="goTo('login')"
            >Pieslēgties</span
          >
        </p>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { ruleSet } from "@/helpers/rules";
import { AuthAPI } from "@/api/apis/authActions";
import { log } from "@/helpers/debugLog";
export default {
  name: "ForgotPasswordView",
  inject: ["cardSize"],
  data: () => {
    return {
      sent: false,
      email: "",
      rules: {
        email: ruleSet.email,
      },
      error: "",
      isLoading: false,
    };
  },
  methods: {
    goTo(route) {
      this.$router.push({ name: route });
    },
    async submitReset() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) return;
      this.isLoading = true;
      AuthAPI.resetPassword(this.email)
        .then((res) => {
          log("ResetPassword", res, 5);
          this.sent = true;
        })
        .catch((err) => {
          let errStr = "";
          for (const key in err.errors) {
            errStr += err.errors[key][0] + "\n";
          }
          this.error = errStr;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.fake-a {
  cursor: pointer;
}

.fake-a:hover {
  color: #1976d2;
}
</style>
