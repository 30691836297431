export const ruleSet = {
  email: [
    (v: string) => !!v || "Šis lauks ir obligāts",
    (v: string) => /.+@.+\..+/.test(v) || "Lūdzu ievadiet derīgu e-pastu",
    (v: string) => (!!v && v.length < 256) || "Ēpastam jābūt īsākam",
  ],
  phoneNumber: [
    (v: string) => !!v || "Šis lauks ir obligāts",
    (v: string) => {
      if (v === undefined || v === null) {
        return false;
      }
      function isN(a: string) {
        if (/(^[0-9]+$)/.test(a) && a.length === 8) {
          return true;
        }
      }
      if (isN(v)) {
        return true;
      }
      if (v.length > 8) {
        const num = v.slice(v.length - 8, v.length);
        const reg = v.slice(0, v.length - 8);
        if (isN(num) && (reg === "+371" || reg === "+371 ")) {
          return true;
        }
      }
      return "Lūdzu ievadiet telefona numuru";
    },
  ],
  persCode: [
    (v: string) => !!v || "Šis lauks ir obligāts",
    (v: string) =>
      /(^[0-9]{6}-+[0-9]{5}$)/.test(v) || "Lūdzu ievadiet derīgu personas kodu",
  ],
  image: [
    (v: File) => !!v || "Šis lauks ir obligāts",
    (v: File) => !v || v.size < 5000000 || "Bildei nevajadzētu pārsniegt 5 MB!",
  ],
  select: [(v: string) => !!v || (v as any) === 0 || "Lūdzu izvēlaties vienu"],
  fileType: [
    (v: any) => {
      return (
        !v ||
        !v.length ||
        ["image/png", "image/jpeg", "image/jpg"].includes(v[0].type) ||
        "Lūdzu izvēlaties png vai jpeg failu"
      );
    },
  ],
};
export class ruleSetGen {
  public static fieldManditory() {
    return (v: string) => !!v || "Šis lauks ir obligāts";
  }

  public static persName(message: string) {
    return [
      this.fieldManditory(),
      (v: string) =>
        (v !== undefined &&
          /^[^\u0021-\u002C\u002E-\u0040\u005B-\u0060\u007B-\u007F\u2C00-\uD7FF]{2,}$/.test(
            v,
          ) &&
          v.length < 256) ||
        message, // \u00BF-\u1FFF
    ];
  }

  public static text(
    message: string,
    isRequired: boolean = true,
    minLen: number = 1,
  ) {
    return [
      isRequired ? this.fieldManditory() : true,
      (v: string) => (v && v.length >= minLen && v.length < 4096) || message,
    ];
  }

  public static number(
    message: string = "Lūdzu ievadiet derīgu numuru",
    isRequired: boolean = true,
  ) {
    return [
      isRequired ? this.fieldManditory() : true,
      (v: string) =>
        (/(^[0-9]+$)/.test(v) && v.toString().length < 256) || message,
    ];
  }

  public static price(
    message: string = "Lūdzu ievadiet derīgu cenu",
    isRequired: boolean = true,
  ) {
    return [
      isRequired ? this.fieldManditory() : true,
      (v: string) =>
        (/^\d*\.?\d*$/.test(v) && v.toString().length < 256) || message,
    ];
  }

  public static phoneNumber(
    message: string = "Lūdzu ievadiet telefona numuru",
    isRequired: boolean = true,
  ) {
    return [
      isRequired ? this.fieldManditory() : true,
      (v: string) => {
        if (!isRequired && (v === undefined || v === null)) {
          return true;
        }
        if (v.length === 0) {
          return true;
        }
        function isN(a: string) {
          if (/(^[0-9]+$)/.test(a) && a.length === 8) {
            return true;
          }
        }
        if (isN(v)) {
          return true;
        }
        if (v.length > 8) {
          const num = v.slice(v.length - 8, v.length);
          const reg = v.slice(0, v.length - 8);
          if (isN(num) && (reg === "+371" || reg === "+371 ")) {
            return true;
          }
        }
        return message;
      },
    ];
  }

  public static persCode(
    message: string = "Lūdzu ievadiet derīgu personas kodu",
    isRequired: boolean = true,
  ) {
    return [
      isRequired ? this.fieldManditory() : true,
      (v: string) => {
        if (!isRequired && (v === null || v === undefined)) {
          return true;
        }
      },
      (v: string) => {
        if (!isRequired && (v === null || v === undefined)) {
          return true;
        }
        if (/(^[0-9]{6}-+[0-9]{5}$)/.test(v) || v.length === 0) {
          return true;
        }
        return message;
      },
    ];
  }

  public static date(message: string = "Lūdzu izvēlaties datumu") {
    return [(v: string) => v.length > 1 || message];
  }

  public static checkedCheckbox(message: string = "Lūdzu atķeksējiet") {
    return [(v: boolean) => v || message];
  }
}
