export function entropy(str: string) {
  const len = str.length;

  // Build a frequency map from the string.
  const frequencies: number[] = Array.from(str).reduce(
    (freq: any, c) => (freq[c] = (freq[c] || 0) + 1) && freq,
    {},
  );

  // Sum the frequency of each character.
  const freq = Object.values(frequencies).reduce(
    (sum: number, f) => sum - (f / len) * Math.log2(f / len),
    0,
  );

  return freq * Math.log(len);
}

export function passwordStrength(password: string) {
  const a = entropy(password) * 10;
  return isNaN(a) ? 0 : a;
}
