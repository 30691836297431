<template>
  <div class="content">
    <h1>Apliecību pasūtījumi</h1>
    <TableContent
      :table-info="userTableInfo"
      name="user_courses"
      filter="filter[is_certificate_ordered]=true&filter[is_certificate_accepted]=false"
    ></TableContent>
  </div>
</template>

<script>
import TableContent from "@/Admin/components/TableContent.vue";
import AllowCourseAccessAction from "@/Admin/components/form_actions/AllowCourseAccessAction.vue";
import AcceptLicenseOrderAction from "@/Admin/components/form_actions/AcceptLicenseOrderAction.vue";

export default {
  name: "LicenseOrderListView",
  components: { TableContent },
  data: () => ({
    userTableInfo: {
      headers: [
        {
          title: "Rēķina nr.",
          align: "start",
          key: "invoice_number",
        },
        {
          title: "Kurss",
          align: "start",
          key: "course.name",
        },
        {
          title: "Vārds",
          align: "start",
          key: "user.firstname",
        },
        {
          title: "Uzvārds",
          align: "start",
          key: "user.lastname",
        },
        {
          title: "E-pasts",
          align: "start",
          key: "user.email",
        },
        {
          title: "Telefona nr.",
          align: "start",
          key: "user.phone_number",
        },
        {
          title: "Piegādes adrese",
          align: "start",
          key: "invoice.delivery_location",
        },
        {
          title: "Darbības",
          align: "start",
          sortable: false,
          key: "actions",
        },
      ],
      actions: [AcceptLicenseOrderAction],
    },
  }),
};
</script>

<style scoped>
.content {
  width: 100%;
}
</style>
