const config = {
  baseURL: process.env.VUE_APP_API_URL,
  headers: new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  }),
  formDataHeaders: new Headers({
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  }),
  token: localStorage.getItem("token") || null,
};

export default config;
export function updateHeaders(token: string | null) {
  config.headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: token ? `Bearer ${token}` : "",
  });
  config.formDataHeaders = new Headers({
    Accept: "application/json",
    Authorization: token ? `Bearer ${token}` : "",
  });
}

export function updateTempHeaders(tempToken: string | null) {
  config.headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-token": tempToken || "",
  });
  config.formDataHeaders = new Headers({
    Accept: "application/json",
    "x-token": tempToken || "",
  });
}
