<template>
  <v-icon icon="mdi-school" @click="redirect()"></v-icon>
</template>

<script>
export default {
  name: "RedirectToCompanyCoursesAction",
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  methods: {
    redirect() {
      this.$router.push(`/admin/company/courses/${this.data.id}`);
    },
  },
};
</script>
