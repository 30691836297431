<template>
  <div v-if="!loadingCourse" class="container">
    <v-img
      class="course-caricature"
      :src="course.image"
      alt="course-caricature"
      cover
    />
    <div class="course-container">
      <v-alert v-if="alert.active" :type="alert.status" class="mb-5">
        {{ alert.message }}
      </v-alert>
      <div class="course-info">
        <div>
          <h1 class="course-title">{{ course.name }}</h1>
        </div>
        <div class="course-info-cards">
          <AttributeCard icon="mdi-clock-outline" :text="courseLength" />
          <AttributeCard icon="mdi-calendar-month-outline" :text="validFor" />
          <AttributeCard
            v-if="course.is_license_card"
            icon="mdi-card-account-details-outline"
            text="Apliecība"
            tooltip-text="Pēc veiksmīgas kursa pabeigšanas, apliecība tiks nogādāta uz Jūsu norādīto, kursa pasūtīšanas laikā, pakomātu."
          />
        </div>
      </div>
      <div v-if="!isLoggedIn" class="pricing">
        <v-btn
          color="primary"
          class="text-lowercase"
          @click="$router.push({ name: 'login' })"
          >Pieslēgties, lai pieteiktu kursu</v-btn
        >
      </div>
      <div
        v-else-if="
          userCourse && userCourse.order_completed && !userCourse.is_completed
        "
        class="pricing"
      >
        <v-btn
          color="primary"
          class="text-lowercase"
          @click="$router.push(`/course/${course.id}/test`)"
          >{{ courseActionStatus() }}
        </v-btn>
        <v-btn
          v-if="userCourse.completed_at"
          color="primary"
          class="text-lowercase"
          variant="outlined"
          @click="$router.push(`/course/${course.id}/test-overview`)"
          >Skatīt kļūdas</v-btn
        >
      </div>
      <div v-else-if="!isOrderStarted" class="pricing">
        <v-tooltip
          class="custom-tooltip"
          max-width="450"
          location="start bottom"
          origin="start top"
        >
          <template #activator="{ props }">
            <v-btn
              class="tooltip-icon"
              variant="plain"
              icon="mdi-help-circle-outline"
              size="x-large"
              v-bind="props"
            />
          </template>
          <span>
            <b>Pilnā apmācība</b> iekļauj materiālu, kas satur visu
            nepieciešamo, lai apgūtu tēmu un veiksmīgi nokārtotu testu.
            <!--            <br /><br />-->
            <!--            Ja pērk tikai <b>piekļuvi testam</b>, Jums būs pieeja tikai pildīt-->
            <!--            testu, un Jūs nesaņemsiet apmācības materiālu. Šī izvēle ir-->
            <!--            paredzēta personām, kuras jau labi pārzin tēmu vai vēlas atjaunot-->
            <!--            apliecību.-->
          </span>
        </v-tooltip>
        <!--        <v-btn-->
        <!--          color="primary"-->
        <!--          variant="outlined"-->
        <!--          class="text-lowercase"-->
        <!--          @click="buyCourse(true)"-->
        <!--          >Pirkt piekļuvi testam - {{ course.half_price }}€-->
        <!--        </v-btn>-->
        <v-btn color="primary" class="text-lowercase" @click="buyCourse(false)"
          >Pirkt piekļuvi apmācībai - {{ course.base_price }}€
        </v-btn>
      </div>
      <div v-if="userCourse && userCourse.course.materials">
        <v-divider />
        <h3 class="materials-title">Lejupielādējiet mācību materiālus</h3>
        <div class="materials">
          <v-card
            v-for="material in userCourse.course.materials"
            :key="material.index"
            class="material-card"
            :prepend-icon="material.icon"
            :title="material.name"
            :subtitle="material.size"
            hover
            @click="downloadMaterial(material.file)"
          >
            <v-card-text>{{ material.description }}</v-card-text>
          </v-card>
        </div>
      </div>
      <v-divider />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="course-description" v-html="course.description" />
    </div>
  </div>
  <div v-else class="loading">
    <v-progress-circular
      indeterminate
      :size="128"
      :width="12"
      color="primary"
    ></v-progress-circular>
  </div>
  <CoursesSection v-if="!IsCompanyUser()" :white-background-color="true" />
</template>

<script>
import { CourseAPI } from "@/api/apis/CoursesActions";
import AttributeCard from "@/components/AttributeCard.vue";
import CoursesSection from "@/components/HomePage/CoursesSection.vue";
import { UserData } from "@/storage/user";

export default {
  name: "CourseView",
  components: { CoursesSection, AttributeCard },
  data() {
    return {
      alert: {
        active: false,
        status: null,
        message: null,
      },
      loadingCourse: true,
      course: {},
      userCourse: undefined,
      isOrderStarted: false,
    };
  },
  computed: {
    isLoggedIn() {
      return UserData.IsLoggedIn;
    },
    courseLength() {
      return `Kurss izpildāms ${this.getLengthText(this.course.length)}`;
    },
    validFor() {
      if (this.course.certificate_valid_for === 0) {
        return "Beztermiņa apliecība";
      } else {
        return `Apliecība derīga ${this.getYearsText(
          this.course.certificate_valid_for,
        )}`;
      }
    },
  },
  mounted() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.loadingCourse = true;
        this.isOrderStarted = false;
        this.userCourse = undefined;
        this.alert = {
          active: false,
          status: null,
          message: null,
        };
        this.getCourse().then(() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        });
        this.getUserCourse();
      },
      { immediate: true },
    );
  },
  methods: {
    async getCourse() {
      return await CourseAPI.course(this.$route.params.id)
        .then((response) => {
          this.course = response.data;
          this.loadingCourse = false;
        })
        .catch(() => {
          this.$router.push({ name: "home" });
        });
    },
    async getUserCourse() {
      if (!UserData.IsCompanyUser) {
        return await CourseAPI.userCourse(this.$route.params.id, 1)
          .then((response) => {
            this.userCourse = response.data;
          })
          .catch((response) => {
            if (response.code === 401) {
              return;
            }
            this.isOrderStarted = true;
            this.alert = {
              active: true,
              status: response.status === "certificate" ? "success" : "warning",
              message: response.message,
            };
          });
      } else {
        return await CourseAPI.companyUser()
          .then((response) => {
            this.userCourse = {
              ...response.data,
              order_completed: true, // thanks BE
            };
          })
          .catch((response) => {
            if (response.code === 401) {
              return;
            }
            this.isOrderStarted = true;
            this.alert = {
              active: true,
              status: response.status === "certificate" ? "success" : "warning",
              message: response.message,
            };
          });
      }
    },
    getYearsText(years) {
      if (years !== 11 && years % 10 === 1) {
        return years + " gadu";
      } else {
        return years + " gadus";
      }
    },
    getLengthText(days) {
      if (days !== 11 && days % 10 === 1) {
        return days + " stundā";
      } else {
        return days + " stundās";
      }
    },
    buyCourse(isHalf) {
      sessionStorage.setItem(
        "digital-product",
        JSON.stringify({
          meta: {
            isHalf,
          },
          product: this.course,
        }),
      );
      this.$router.push({ name: "checkout-digital" });
    },
    courseActionStatus() {
      if (this.userCourse.is_completed) {
        return;
      }
      if (this.userCourse.is_started) {
        return "Turpināt testu";
      }
      return "Sākt testu";
    },
    downloadMaterial(url) {
      window.open(url, "_blank");
    },
    IsCompanyUser() {
      return UserData.IsCompanyUser;
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  margin-inline: auto;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.course-caricature {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 0;
  height: 250px;
  width: 100%;
  margin: 150px 0 0;
}

.course-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 35px;
  background-color: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.course-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.course-info-cards {
  display: flex;
  gap: 10px;
}

.course-title {
  font-weight: 500;
}

.pricing {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.custom-tooltip :deep(.v-overlay__content) {
  padding: 11px 16px !important;
}

.tooltip-icon {
  width: 36px;
  height: 36px;
}

.materials-title {
  margin-top: 15px;
  margin-bottom: 15px;
}

.materials {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 25px;
}

.material-card :deep(i) {
  font-size: 48px !important;
}

.material-card :deep(.v-avatar.v-avatar--density-default) {
  height: initial !important;
  width: initial !important;
}

@media only screen and (width <= 1110px) {
  .course-info-cards {
    flex-direction: column;
  }

  .pricing {
    flex-direction: column;
  }
}

@media only screen and (width <= 830px) {
  .container {
    width: 100% !important;
  }

  .course-container {
    padding: 10px !important;
  }
}
</style>
