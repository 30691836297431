import { createStore } from "vuex";

export default createStore({
  state: {
    all: {},
  },
  getters: {
    field: (state) => (data) => {
      let a = state.all;
      for (let i = 0; i < data.location.length; i++) {
        const element = data.location[i];
        if (a[element] === undefined) {
          return undefined;
        }
        a = a[element];
      }
      return a;
    },
  },
  mutations: {
    setData(state, payload) {
      let a = state.all;
      for (let i = 0; i < payload.location.length - 1; i++) {
        const element = payload.location[i];
        if (a[element] === undefined) {
          a[element] = {};
        }
        a = a[element];
      }
      a[payload.location[payload.location.length - 1]] = payload.value;
    },
  },
  actions: {},
  modules: {},
});
