<template>
  <v-container class="form-container rounded-lg">
    <h1>Izveidot kursu</h1>
    <CreateForm :fields="fields" name="courses" />
  </v-container>
</template>

<script>
import { markRaw } from "vue";
import CreateForm from "@/Admin/components/CreateForm.vue";
import TextField from "@/Admin/components/form/TextField.vue";
import TextEditorField from "@/Admin/components/form/TextEditorField.vue";

import { ruleSetGen } from "@/Admin/helpers/rules";
import config from "@/api/config";
import ImagePickField from "@/Admin/components/form/ImagePickField.vue";
import SelectField from "@/Admin/components/form/SelectField.vue";
export default {
  name: "AdminArticlesCreateView",
  components: { CreateForm },
  data() {
    return {
      fields: {
        image: {
          component: markRaw(ImagePickField),
          data: {
            label: "Izvēlieties kursa attēlu",
            name: "image",
            size: 700,
          },
        },
        name: {
          component: markRaw(TextField),
          data: {
            label: "Nosaukums",
            placeholder: "Ievadiet nosaukumu",
            name: "name",
            rules: ruleSetGen.text("Lūdzu ievadiet derīgu nosaukumu", true, 3),
          },
        },
        description: {
          component: markRaw(TextEditorField),
          data: {
            label: "Kursa apraksts",
            placeholder: "Ievadiet aprakstu",
            name: "description",
            rules: ruleSetGen.text("Lūdzu ievadiet derīgu aprakstu", true, 3),
          },
        },
        base_price: {
          component: markRaw(TextField),
          data: {
            label: "Kursa pilnā cena",
            placeholder: "Ievadiet cenu",
            name: "base_price",
            rules: ruleSetGen.price("Lūdzu ievadiet derīgu cenu", true, 1),
          },
        },
        length: {
          component: markRaw(TextField),
          data: {
            label: "Kurss pabeidzams (stundās)",
            placeholder: "Ievadiet stundas",
            name: "length",
            rules: ruleSetGen.number("Lūdzu ievadiet derīgu laiku", true, 1),
          },
        },
        is_briefing: {
          component: markRaw(SelectField),
          data: {
            label: "Vai šī būs kā instruktāža:",
            placeholder: "Izvēlieties vērtību",
            name: "is_briefing",
            items: [
              { id: 0, value: "Nav" },
              { id: 1, value: "Ir" },
            ],
            itemTitle: "value",
            itemValue: "id",
          },
        },
        category_id: {
          component: markRaw(SelectField),
          data: {
            label: "Kursa kategorija",
            placeholder: "Izvēlieties kategoriju",
            name: "category_id",
            items: [],
            itemTitle: "name",
            itemValue: "id",
          },
        },
        test_id: {
          component: markRaw(SelectField),
          data: {
            label: "Tests",
            placeholder: "Izvēlieties testu",
            name: "test_id",
            items: [],
            itemTitle: "title",
            itemValue: "id",
          },
        },
        certificate_valid_for: {
          component: markRaw(TextField),
          data: {
            label: "Apliecība būs derīga",
            placeholder: "Ievadiet sertifikāta derīguma termiņu",
            name: "certificate_valid_for",
            rules: ruleSetGen.number(
              "Lūdzu ievadiet derīguma termiņu",
              true,
              1,
            ),
          },
        },
        visibility: {
          component: markRaw(SelectField),
          data: {
            label: "Redzamība",
            placeholder: "Izvēlieties vai ir redzamību",
            name: "visibility",
            items: [
              { id: 0, value: "Publisks" },
              { id: 1, value: "Uzņēmumam lokāls" },
              { id: 2, value: "Privāts administratoriem" },
            ],
            itemTitle: "value",
            itemValue: "id",
          },
        },
        is_license_card: {
          component: markRaw(SelectField),
          data: {
            label: "Apliecības tips:",
            placeholder: "Izvēlieties apliecību",
            name: "is_license_card",
            items: [
              { id: 0, value: "A4 formāta apliecība" },
              { id: 1, value: "Kredītkartes formāta apliecība" },
            ],
            itemTitle: "value",
            itemValue: "id",
          },
        },
      },
    };
  },
  async created() {
    await fetch(`${config.baseURL}/course_categories`, {
      method: "GET",
      headers: config.headers,
    })
      .then((response) => response.json())
      .then((response) => {
        this.fields.category_id.data.items = response.data;
      });

    await fetch(`${config.baseURL}/tests`, {
      method: "GET",
      headers: config.headers,
    })
      .then((response) => response.json())
      .then((response) => {
        this.fields.test_id.data.items = response.data;
      });
  },
};
</script>

<style scoped>
.form-container {
  width: 1000px;
  margin: auto;
  background-color: white;
  padding: 10px;
}
</style>
