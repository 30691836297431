<template>
  <section class="hero-section">
    <div class="hero-section-content">
      <div class="hero-section-text">
        <h1 class="hero-section-large-text">
          Drošības
          <span class="hero-section-gradient-text">meistarklase</span> Atklāj
          aizsardzības īstenību kopā ar mums!
        </h1>
        <p class="hero-section-paragraph">
          Izveido drošu darba vidi ar mūsu palīdzību! Mēs sniedzam apmācību par
          darba aizsardzības organizatoriskajām struktūrām un citām
          nepieciešamajām darbībām.
        </p>
        <div class="hero-section-buttons d-flex mt-3" style="gap: 10px">
          <v-btn
            v-if="!isLoggedIn"
            variant="flat"
            color="primary"
            class="text-lowercase"
            @click="$router.push({ name: 'login' })"
          >
            Pieslēgties
          </v-btn>
          <v-btn
            :variant="isLoggedIn ? 'flat' : 'outlined'"
            color="primary"
            class="text-lowercase"
            @click="$router.push({ name: 'about-us' })"
          >
            Par mums
          </v-btn>
        </div>
        <div class="hero-section-scroll-container d-flex align-center">
          <div class="hero-section-scroll-mice d-flex">
            <img :src="require('@/assets/images/mice/stroke.svg')" />
            <img
              class="hero-section-scroll-mice-wheel"
              :src="require('@/assets/images/mice/wheel.svg')"
            />
          </div>
          <p class="hero-section-scroll-text">Skaties tālāk</p>
        </div>
      </div>
    </div>
    <div class="hero-section-image-container">
      <ImageWithFallback
        class="hero-section-image"
        :webp-src="require('@/assets/images/man/man-min.webp')"
        :src="require('@/assets/images/man/man-min.png')"
        alt="hero-section-image"
      />
    </div>
  </section>
</template>
<script>
import ImageWithFallback from "@/components/ImageWithFallback.vue";
import { UserData } from "@/storage/user";

export default {
  components: {
    ImageWithFallback,
  },
  computed: {
    isLoggedIn() {
      return UserData.IsLoggedIn;
    },
  },
};
</script>

<style scoped>
.hero-section {
  max-width: max(80vw, 1453px);
  width: 100%;
  height: min(100vh, 980px);
  display: flex;
}

.hero-section-content {
  display: flex;
  align-items: center;
  height: min(100vh, 980px);
  width: 60%;
  z-index: 10;
}

.hero-section::before {
  content: "";
  position: absolute;
  height: min(100vh, 980px);
  width: 100vw;
  top: 0;
  left: 0;
  background: linear-gradient(78.42deg, #e8f6ff 51.04%, #fff 100%);
}

.hero-section-large-text {
  font-weight: 600;
  font-size: 70px;
  line-height: 77px;
  color: #262626;
  user-select: none;
}

.hero-section-gradient-text {
  background: linear-gradient(211.58deg, #70c8fe 15.66%, #1ca7fd 33.02%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.hero-section-paragraph {
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  color: #454545;
  margin-top: 10px;
}

.hero-section-text {
  margin-top: -50px;
}

.hero-section-image-container {
  position: relative;
  height: min(100vh, 980px);
}

.hero-section-image > img {
  height: 100% !important;
}

.hero-section-image {
  position: absolute;
  bottom: 0;
  left: 80px;
  height: 80%;
  transform-origin: bottom;
}

.hero-section-scroll-container {
  gap: 10px;
  animation: 7s ease-in-out show-mice forwards;
  transition: 0.1s all;
  margin-top: 50px;
}

.hero-section-scroll-text {
  color: #0d4b72;
}

.hero-section-scroll-mice {
  position: relative;
}

.hero-section-scroll-mice-wheel {
  position: absolute;
  left: calc(50% - 2px);
  top: 7px;
  animation: 1.4s ease-in-out bounce infinite;
}

@media only screen and (width <= 1600px) {
  .hero-section-large-text {
    font-size: 45px;
    line-height: 110%;
  }
}

@media only screen and (width <= 1280px) {
  .hero-section-image {
    display: none;
  }

  .hero-section-content,
  .hero-section-scroll-container {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .hero-section-buttons {
    justify-content: center;
  }

  .hero-section-buttons button {
    font-size: 16px !important;
    padding: 13px !important;
    height: 100% !important;
  }
}

@media only screen and (width <= 800px) {
  .hero-section-text {
    margin-top: 50px;
  }

  .hero-section-large-text {
    font-size: 45px;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes show-mice {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  80% {
    opacity: 1;
  }
}
</style>
