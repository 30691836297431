<template>
  <v-card height="196" hover class="d-flex article-container rounded">
    <v-img class="article-image rounded" :src="article.image" cover />
    <div class="w-100 d-flex justify-space-between flex-column">
      <v-card-title class="article-title">{{ article.title }}</v-card-title>
      <v-card-text>{{
        article.text.substring(0, 70).replace(/<[^>]*>?/gm, "") + "..."
      }}</v-card-text>
      <v-card-actions
        class="d-flex w-100 justify-space-between align-center mr-2"
      >
        <v-card-text>{{
          new Date(article.created_at).toLocaleDateString()
        }}</v-card-text>
        <v-btn
          variant="flat"
          color="primary"
          class="text-lowercase"
          @click="$router.push(`/articles/${article.id}`)"
          >Skatīt</v-btn
        >
      </v-card-actions>
    </div>
  </v-card>
</template>
<script>
export default {
  props: {
    article: {
      type: Object,
      default: null,
    },
  },
};
</script>
<style scoped>
.article-title {
  white-space: normal;
  display: inline-block;
  word-wrap: break-word;
}

.article-image {
  max-width: 200px !important;
  margin: 10px;
  width: 100%;
}

.article-container {
  padding-right: 20px;
}

@media only screen and (width <= 830px) {
  .article-image {
    object-fit: cover;
    max-width: 100% !important;
    max-height: 200px;
    margin: unset !important;
    border-bottom-left-radius: unset !important;
    border-bottom-right-radius: unset !important;
  }

  .article-container {
    flex-direction: column;
    height: 100% !important;
    width: 100% !important;
    padding-right: unset;
  }
}
</style>
