<template>
  <v-icon icon="mdi-download" @click="download()"></v-icon>
</template>

<script>
export default {
  name: "EditAction",
  props: {
    data: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
  methods: {
    download() {
      window.open(this.data.export_url, "_blank").focus();
    },
  },
};
</script>
