<template>
  <v-app :style="{ backgroundColor: $route.meta.backgroundColor ?? 'white' }">
    <component :is="$route.meta.layout">
      <router-view />
    </component>
  </v-app>
</template>
<script>
import { initialize } from "@/storage/initialize";
export default {
  created() {
    initialize(this.$store);
  },
};
</script>
