<template>
  <div class="content">
    <div class="d-flex align-center w-100 justify-space-between">
      <h1>Uzņēmuma kursi</h1>
      <v-btn class="mx-4" @click="createDialog = !createDialog">
        Pievienot
      </v-btn>
    </div>
    <TableContent
      ref="table"
      :table-info="userTableInfo"
      :name="`company_imports/courses/${$route.params.id}`"
      redirect="company/courses/"
    ></TableContent>

    <v-dialog v-model="createDialog" width="500px">
      <v-card>
        <v-card-title>Pievienot jaunu mācību grupu</v-card-title>
        <p class="mx-5">
          Pēc veiksmīgas pievienošanas tiks automātiski lejupielādēts excel
          fails ar kursa dalībniekiem
        </p>
        <v-form ref="form" class="mx-5 my-5" @submit.prevent>
          <v-alert
            v-show="failure"
            type="error"
            class="mb-5"
            :text="failureMessage"
          ></v-alert>
          <v-select
            v-model="form.course_id"
            label="Kurss"
            :items="courses"
            item-title="name"
            item-value="value"
            :rules="rules.course_id"
            persistent-hint
            return-object
            single-line
            class="my-2"
          ></v-select>
          <v-file-input
            v-model="form.file"
            class="my-2"
            label="Dalībnieki"
            :rules="rules.file"
          ></v-file-input>
          <v-btn
            type="submit"
            block
            class="mt-5"
            text="Pievienot"
            @click="submit"
          ></v-btn>
          <v-btn
            block
            class="mt-5"
            text="Aizvērt"
            @click="createDialog = false"
          ></v-btn>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TableContent from "@/Admin/components/TableContent.vue";
import config from "@/api/config";
import { ruleSet, ruleSetGen } from "@/Admin/helpers/rules";
import DownloadAction from "@/Admin/components/form_actions/DownloadAction.vue";
export default {
  name: "CompanyCoursesListView",
  components: { TableContent },
  data: () => ({
    createDialog: false,
    failure: false,
    failureMessage: null,
    userTableInfo: {
      headers: [
        {
          title: "Instruktāžas nr.",
          align: "start",
          key: "id",
        },
        {
          title: "Kursa nosaukums",
          align: "start",
          key: "course.name",
        },
        {
          title: "Izveidots",
          align: "start",
          key: "created_at",
        },
        {
          title: "Darbības",
          align: "start",
          sortable: false,
          key: "actions",
        },
      ],
      actions: [DownloadAction],
    },
    form: {
      course_id: null,
      file: null,
    },
    rules: {
      course_id: ruleSet.select,
      file: ruleSetGen.text("Aizpildiet lauku", true),
    },
    courses: [],
  }),
  async mounted() {
    await fetch(`${config.baseURL}/courses`, {
      method: "GET",
      headers: config.headers,
    })
      .then((response) => response.json())
      .then((response) => {
        this.courses = response.data.map((course) => {
          return { value: course.id, name: course.name };
        });
      });
  },
  methods: {
    async submit() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) return;
      const fd = new FormData();
      fd.append("course_id", this.form.course_id.value);
      fd.append("file", this.form.file[0]);
      await fetch(
        `${config.baseURL}/company_users_import/${this.$route.params.id}`,
        {
          method: "POST",
          headers: config.formDataHeaders,
          body: fd,
        },
      ).then(async (response) => {
        const body = await response.json();
        if (response.ok) {
          window.open(body.export_url, "_blank").focus();
          this.$refs.table.actionChange();
          this.createDialog = false;
          this.form = {
            course_id: null,
            file: null,
          };
        } else {
          this.failure = true;
          this.failureMessage = body.message;
        }
      });
    },
  },
};
</script>

<style scoped>
.content {
  width: 100%;
}
</style>
