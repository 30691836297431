<template>
  <div class="card-container">
    <v-icon :icon="icon" />
    <p>{{ text }}</p>
    <v-tooltip
      v-if="tooltipText"
      class="custom-tooltip"
      max-width="450"
      location="start bottom"
      origin="start top"
    >
      <template #activator="{ props }">
        <v-btn
          class="tooltip-icon"
          variant="plain"
          icon="mdi-help-circle-outline"
          v-bind="props"
        />
      </template>
      <span>{{ tooltipText }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    tooltipText: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped>
.card-container {
  display: flex;
  gap: 10px;
  padding: 10px 15px;
  border-radius: 8px;
  background-color: #f5f5f5;
  width: fit-content;
}

.custom-tooltip :deep(.v-overlay__content) {
  padding: 11px 16px !important;
}

.tooltip-icon {
  width: auto;
  height: auto;
}
</style>
