<template>
  <div class="container">
    <div class="header">
      <h1>
        Mēs palīdzēsim jums apgūt <br />
        jaunas zināšanas
      </h1>
      <!--      <v-img-->
      <!--        class="about-us-caricature"-->
      <!--        :src="require('@/assets/images/caricature/aboutUsPageCaricature.png')"-->
      <!--        alt="about-us-caricature"-->
      <!--      ></v-img>-->
    </div>
    <div class="content">
      <p class="content-header">MŪSU STĀSTS</p>
      <h2 class="content-title">Kas mēs esam?</h2>
      <div class="content-text">
        <p>
          Kopš 2007.gada SIA „Guna BSK” veiksmīgi darbojas Latvijas tirgū kā
          Kompetenta institūcija darba aizsardzības jomā: sniedz pakalpojumus
          uzņēmumiem, valsts un pašvaldības iestādēm. SIA “Guna BSK” veic darba
          vides risku izvērtēšanu, pārrauga darba aizsardzības sistēmu, organizē
          mērījumus, sakārto darba aizsardzības dokumentāciju, atbilstoši spēkā
          esošajiem normatīvajiem aktiem, apmāca klientu darbiniekus drošām
          darba metodēm. Uzņēmuma juridiskā adrese ir Saulaine 21 -29, Bauskas
          novadā, faktiskā adrese Bauska, Uzvaras iela 3. Uzņēmuma darbība ir
          visā Latvijā, īpaši Bauskas, Jelgavas, Ādažu novados, Rīgā un tās
          apkaimē.
        </p>
        <p>
          SIA „Guna BSK” Kvalitātes vadības sistēma sertificēta no 2007. gada 9.
          jūlija, tā nodrošina klientu, LR normatīvo aktu un citu regulējošo
          prasību izpildi, kā arī uzņēmuma noteikto prasību izpildi.
        </p>
        <p>
          Rūpes par sniegto pakalpojumu kvalitāti, kā arī maksimāli pilnīga
          klientu prasību nodrošināšana, paliek par galveno prioritāti katra
          „SIA Guna BSK” darbinieka ikdienas darbā. Jau daudzus gadus sniedzam
          darba aizsardzības, ugunsdrošības, civilās aizsardzības, kvalitātes
          vadības sistēmas, pārtikas nekaitīguma nodrošināšanas sistēmas
          pakalpojumus. Izstrādāti tiek dokumenti, apmācīti darbinieki, kā arī
          nepārtraukti pārraudzīts un uzturēts pakalpojums. Visi uzņēmuma
          sasniegumi ir kopīga darba rezultāts, lielākoties kolektīva radoša
          pieeja, pieaugoša profesionalitāte un mūsdienīgu metožu izmantošana
          procesu organizācijā.
        </p>
        <p>
          Uzņēmumā strādā profesionāla darbinieku komanda, ik gadu paaugstināta
          tiek kvalifikācija- augam mēs, aug mūsu pieredze, zināšanas un
          profesionālās prasmes.
        </p>
      </div>
    </div>
    <div class="content">
      <p class="content-header">MĀCĪBAS</p>
      <h2 class="content-title">Izglītības iestāde</h2>
      <div class="content-text">
        <p>
          „Profesionālais tālākizglītības centrs 'Drošam darbam”, ir SIA Guna
          BSK dibināta profesionālā izglītības iestāde, kas reģistrēta
          Izglītības kvalitātes valsts dienestā 2011. gada 25. jūlijā.”
        </p>
        <p>
          Izglītības iestādes galvenais mērķis ir piedāvāt pieaugušajiem
          izglītības programmas Latvijā. Iestādē strādā pedagogi ar atbilstošu
          izglītību un kvalifikāciju saskaņā ar Ministru kabineta noteikumiem.
          Lai sasniegtu savus mērķus, izglītības iestāde piesaista pieredzējušus
          vieslektorus ar augstu atbildības sajūtu pret izglītojamajiem.
        </p>
        <p>
          Tā ir izveidota ar mērķi ļaut ikvienam iedzīvotājam ar iepriekšējo
          izglītību apgūt profesionālās pilnveides izglītības programmas, lai
          papildinātu savas zināšanas un prasmes darba tirgū.
        </p>
      </div>
    </div>
    <div class="content">
      <p class="content-header">PALĪDZĪBA</p>
      <h2 class="content-title">
        Katram klientam strādājam <br />
        individuāli
      </h2>
      <div class="content-body">
        <div class="content-text-2">
          <li>
            Mēs atradīsim labāko risinājumu jums, jūsu uzņēmumam, jūsu
            darbiniekiem un drošai darba videi.
          </li>
          <li>
            Varam pilnībā vai arī daļēji pārraudzīt jums nepieciešamo sistēmu
            (darba aizsardzība, ugunsdrošība, kvalitātes vadība ISO 9001:2015,
            pārtikas nekaitīguma nodrošināšana sistēma)
          </li>
          <li>
            Ja klientam jau ir sistēma uzsākta veidot, padarīto ņemsim vērā,
            darīsim nepieciešamo, konsultējoties ar klientu.
          </li>
          <li>
            Ja klientam ir dokumentācija kārtībā, tad SIA “Guna BSK” pārraudzīs
            un uzturēs jūsu sistēmu.
          </li>
          <li>
            Klients maksā par padarīto darbu un kvalitatīvu rezultātu, nevis par
            procesu.
          </li>
          <li>
            Jautā - runāsim un vienosimies par labāko, izdevīgāko risinājumu Tev
            un Tavam uzņēmumam.
          </li>
        </div>
        <v-img
          class="about-us-certified"
          :src="require('@/assets/images/certifiedISO/image.png')"
          alt="certificate"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import ImageWithFallback from "@/components/ImageWithFallback.vue";
export default {
  name: "AboutUsView",
  // components: { ImageWithFallback },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 100px;
  margin-bottom: 100px;
  margin-top: 150px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 50px;
}

.header > h1 {
  margin-top: 50px;
  font-size: 56px;
  text-align: center;
}

.about-us-caricature {
  margin: 0;
  padding: 0;
  height: auto !important;
  width: 100% !important;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
}

.content-header {
  color: #188ed7;
  font-size: 22px;
  font-weight: 250;
  letter-spacing: 3.6px;
  text-align: left;
}

.content-title {
  font-size: 42px;
}

.content-text {
  font-size: 20px;
  text-indent: 30px;
  padding: 10px;
}

.content-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.content-text-2 {
  font-size: 20px;
  width: 65%;
  display: flex;
  flex-wrap: wrap;
}

.about-us-certified {
  width: 200px;
}

@media only screen and (width <= 1290px) {
  .content-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .content-text-2 {
    font-size: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
  }

  .about-us-certified {
    margin-top: 20px;
    align-self: center;
    width: 360px;
  }
}

@media only screen and (width <= 1000px) {
  .header > h1 {
    font-size: 46px;
  }

  .content-header {
    font-size: 18px;
  }

  .content-title {
    font-size: 32px;
  }

  .content-text {
    font-size: 17px;
  }
}

@media only screen and (width <= 800px) {
  .container {
    gap: 50px;
  }

  .header > h1 {
    font-size: 46px;
  }

  .about-us-caricature {
    display: none;
  }

  .content-header {
    font-size: 18px;
  }

  .content-title {
    font-size: 32px;
  }

  .content-text {
    font-size: 17px;
  }
}
</style>
