import { API } from "../apiActions";
import config from "../config";
import { privateUserInfo, publicUserInfo } from "@/helpers/types";
import { StatusCodes } from "@/helpers/enums";

type ProfileType = {
  status: string;
  user: privateUserInfo;
  message: string;
};

type course = {
  id: number;
  name: string;
};

type UserCourseType = {
  id: number;
  certificate_url: string | null;
  restricted_course: boolean;
  order_completed: boolean;
  score: number;
  score_string: string;
  correct_answers: number;
  is_completed: boolean;
  completed_at: string | null;
  course: course;
  valid_until: string;
  certificate_valid_until: string | null;
  is_started: boolean;
  created_at: string;
};

type InvoiceType = {
  id: number;
  number: string;
  invoice_url: string;
  user: publicUserInfo;
  type: {
    id: number;
    name: string;
  };
  status: StatusCodes;
  created_at: string;
  without_vat: number;
  vat: number;
  total: number;
};

type InvoiceArrayType = {
  data: InvoiceType[];
  meta: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

export class ProfileAPI extends API {
  public static updateProfile(user: any) {
    const formData = new FormData();
    if (user.avatar.size) {
      formData.append("avatar", user.avatar);
    }
    formData.append("firstname", user.firstname);
    formData.append("lastname", user.lastname);
    formData.append("email", user.email);
    if (user.phone_number) {
      formData.append("phone_number", user.phone_number);
    }
    if (user.personal_code) {
      formData.append("personal_code", user.personal_code);
    }

    return this.__successOnly<ProfileType>(
      fetch(`${config.baseURL}/update_profile?_method=PUT`, {
        method: "POST",
        headers: config.formDataHeaders,
        body: formData,
      }),
      "/update_profile",
    );
  }

  public static courses(isCompleted: number, page: number, pagination: number) {
    return this.__successOnly<UserCourseType>(
      fetch(
        `${config.baseURL}/user_ordered_courses/${isCompleted}?page=${page}&pagination=${pagination}`,
        {
          method: "GET",
          headers: config.headers,
        },
      ),
      "/user_ordered_courses",
    );
  }

  public static invoices(page: number, pagination: number) {
    return this.__successOnly<InvoiceArrayType>(
      fetch(
        `${config.baseURL}/user_orders?page=${page}&pagination=${pagination}`,
        {
          method: "GET",
          headers: config.headers,
        },
      ),
      "/user_orders",
    );
  }

  public static invoice(id: number) {
    return this.__successOnly<InvoiceType>(
      fetch(`${config.baseURL}/user_order/${id}`, {
        method: "GET",
        headers: config.headers,
      }),
      "/user_orders",
    );
  }
}
