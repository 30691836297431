<template>
  <v-container class="form-container rounded-lg">
    <h1>Labot uzņēmumu</h1>
    <EditForm :fields="fields" name="companies" />
  </v-container>
</template>

<script>
import { markRaw } from "vue";
import EditForm from "@/Admin/components/EditForm.vue";
import TextField from "@/Admin/components/form/TextField.vue";

import { ruleSetGen } from "@/Admin/helpers/rules";
import config from "@/api/config";
export default {
  name: "CompaniesEditView",
  components: { EditForm },
  data() {
    return {
      fields: {
        name: {
          component: markRaw(TextField),
          data: {
            label: "Nosaukums",
            placeholder: "Ievadiet nosaukumu",
            name: "name",
            rules: ruleSetGen.text("Lūdzu ievadiet derīgu nosaukumu", true, 3),
          },
        },
        registration_number: {
          component: markRaw(TextField),
          data: {
            label: "Reģistrācijas nr.",
            placeholder: "Ievadiet reģistrācijas nr.",
            name: "registration_number",
            rules: ruleSetGen.text(
              "Lūdzu ievadiet derīgu reģistrācijas nr.",
              true,
              12,
            ),
          },
        },
      },
    };
  },
  async created() {
    await fetch(`${config.baseURL}/companies/${this.$route.params.id}`, {
      method: "GET",
      headers: config.headers,
    })
      .then((response) => response.json())
      .then((response) => {
        for (const key in this.fields) {
          this.fields[key].data.value = response.data[key];
        }
      });
  },
};
</script>

<style scoped>
.form-container {
  width: 1000px;
  margin: auto;
  background-color: white;
  padding: 10px;
}
</style>
