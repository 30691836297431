<template>
  <div class="content">
    <h1>Notikumi</h1>
    <TableContent :table-info="userTableInfo" name="articles"></TableContent>
  </div>
</template>

<script>
import TableContent from "@/Admin/components/TableContent.vue";
import DeleteAction from "@/Admin/components/form_actions/DeleteAction.vue";
import EditAction from "@/Admin/components/form_actions/EditAction.vue";

export default {
  name: "AdminArticlesListView",
  components: { TableContent },
  data: () => ({
    userTableInfo: {
      headers: [
        {
          title: "Nosaukums",
          align: "start",
          key: "title",
        },
        {
          title: "Izveidoja",
          align: "start",
          key: "user.firstname",
        },
        {
          title: "Izveidots",
          align: "start",
          key: "created_at",
        },
        {
          title: "Darbības",
          align: "start",
          sortable: false,
          key: "actions",
        },
      ],
      actions: [EditAction, DeleteAction],
    },
  }),
};
</script>

<style scoped>
.content {
  width: 100%;
}
</style>
