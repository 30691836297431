<template>
  <v-icon
    icon="mdi-checkbox-marked-circle-auto-outline"
    @click="dialog = true"
  ></v-icon>
  <v-dialog v-model="dialog" width="500px">
    <v-card>
      <v-card-text>
        Vai vēlaties apstiprināt, ka lietotājam tika nosūtīta apliecība?
      </v-card-text>
      <v-container class="d-flex justify-space-between align-center">
        <v-btn color="primary" @click="deliverLicenseCardOrder">Nosūtīt</v-btn>
        <v-btn color="primary" variant="text" @click="dialog = false"
          >Aizvērt</v-btn
        >
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import config from "@/api/config";

export default {
  name: "AllowCourseAccessAction",
  props: {
    data: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
  emits: ["change"],
  data: () => {
    return {
      dialog: false,
    };
  },
  methods: {
    async deliverLicenseCardOrder() {
      await fetch(`${config.baseURL}/license_card/deliver/${this.data.id}`, {
        method: "GET",
        headers: config.headers,
      }).then(() => {
        this.dialog = false;
        this.$emit("change");
      });
    },
  },
};
</script>
