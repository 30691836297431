<template>
  <v-form ref="form" class="offset" @submit.prevent>
    <v-row>
      <v-col order="1" md="8" sm="12">
        <v-stepper
          v-if="$vuetify.display.mdAndUp"
          vertical
          hide-actions
          :model-value="stepperState"
          class="rounded-lg mb-8"
        >
          <v-stepper-header>
            <template v-for="(item, i) in stepperHeaders" :key="i">
              <v-stepper-item
                :title="item.title"
                :value="item.value"
                :rules="item.value ? undefined : item.rule"
                :complete="item.isValid"
                :color="item.isValid ? 'success' : 'primary'"
              ></v-stepper-item>
              <v-divider v-if="i < 2"></v-divider>
            </template>
          </v-stepper-header>
        </v-stepper>
        <v-card v-if="!isSent" class="rounded-lg">
          <v-card-title>
            <h1 class="text-h6 text-sm-h7 white-space">Pircēja informācija</h1>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.firstname"
                  :rules="rules.firstname"
                  variant="underlined"
                  color="primary"
                  label="Vārds"
                  autocomplete="off"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.lastname"
                  :rules="rules.lastname"
                  variant="underlined"
                  color="primary"
                  label="Uzvārds"
                  autocomplete="off"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.email"
                  :rules="rules.email"
                  variant="underlined"
                  color="primary"
                  label="E-pasts"
                  autocomplete="email"
                  disabled
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.phone_number"
                  :rules="rules.phone_number"
                  variant="underlined"
                  color="primary"
                  label="Telefona numurs"
                  autocomplete="off"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="user.personal_code"
                  :rules="rules.personal_code"
                  variant="underlined"
                  color="primary"
                  label="Personas kods"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <div v-if="orderedProduct.is_license_card" class="my-8">
            <v-card-title>
              <h1 class="text-h6 text-sm-h7 white-space mb-1">Piegāde</h1>
              <p class="delivery-description">
                Pēc veiksmīgas kursa pabeigšanas, apliecība tiks nosūtīta uz
                Jūsu norādīto Omniva pakomātu.
              </p>
            </v-card-title>
            <v-card-text>
              <v-autocomplete
                v-model="user.address"
                :rules="rules.address"
                :items="pickupPoints"
                variant="underlined"
                color="primary"
                label="Izvēlaties Omniva pakomātu"
                autocomplete="off"
              ></v-autocomplete>
            </v-card-text>
          </div>
          <div class="my-8">
            <v-card-title>
              <h1 class="text-h6 text-sm-h7 white-space mb-1">
                Apmaksas veids
              </h1>
            </v-card-title>
            <v-card-text>
              <v-radio-group v-model="selectPayment">
                <v-radio
                  label="Apmaksa ar rēķinu"
                  value="direct"
                  color="grey-darken-1"
                ></v-radio>
              </v-radio-group>
              <p class="">
                Jūs ēpastā saņemsiet ēpastu ar rēķinu un instrukcijas kā to
                apmaskāt.
              </p>
            </v-card-text>
          </div>
          <v-alert v-if="error" class="mx-3 my-2" type="error">{{
            error
          }}</v-alert>
          <v-card-actions v-if="$vuetify.display.smAndUp">
            <v-spacer />
            <v-btn
              class="mx-2 my-1 py-1 px-8 text-lowercase"
              variant="text"
              @click="goBack"
              >Atgriezties atpakaļ</v-btn
            >
            <!-- Missing font weight  -->
            <v-btn
              class="mx-2 my-1 py-1 px-8 font-weight-medium text-lowercase"
              variant="elevated"
              color="primary"
              type="submit"
              :disabled="isLoading"
              :loading="isLoading"
              @click="order"
              >Pasūtīt</v-btn
            >
          </v-card-actions>
          <v-card-actions v-else class="d-flex flex-column">
            <!-- Missing font weight  -->
            <v-btn
              class="mx-2 my-1 py-1 px-8 font-weight-medium text-lowercase"
              block
              variant="elevated"
              color="primary"
              type="submit"
              :disabled="isLoading"
              :loading="isLoading"
              @click="order"
              >Pasūtīt</v-btn
            >
            <v-btn
              class="mx-2 my-1 py-1 px-8 text-lowercase"
              block
              variant="text"
              @click="goBack"
              >Atgriezties atpakaļ</v-btn
            >
          </v-card-actions>
        </v-card>
        <v-card v-else class="rounded-lg d-flex flex-column align-center">
          <v-card-title
            ><h1 class="text-h6 text-sm-h7 white-space">
              Pasūtījums saņemts!
            </h1></v-card-title
          >
          <v-card-text class="d-flex flex-column align-center">
            <p class="mx-1 my-2">
              Rēķinā būs pieejama sīkāka informācija par pirkumu.
            </p>
            <v-btn
              class="mx-2 my-1 py-1 px-8 font-weight-medium text-lowercase"
              variant="elevated"
              color="primary"
              type="submit"
              :disabled="isLoading"
              :loading="isLoading"
              @click="downloadInvoice"
              >Lejupielādēt rēķinu</v-btn
            >
          </v-card-text>
          <v-card-text class="mx-1">
            <p>Ar Jums sazināsies tuvākajās 24 stundās.</p>
            <p>Vairāk varat uzzināt apmaksas sadaļā.</p>
          </v-card-text>
          <v-card-text>
            <v-btn
              class="my-1 py-1 px-8 font-weight-medium text-lowercase"
              variant="outlined"
              color="primary"
              type="submit"
              :disabled="isLoading"
              :loading="isLoading"
              @click="goToProfile"
              >Atgriezties uz profilu</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>
      <v-col order="0" order-md="2">
        <v-card class="mb-9 px-3 pb-1">
          <v-card-text
            class="d-flex justify-space-between px-1"
            style="gap: 15px"
          >
            <div class="d-flex flex-column align-start">
              <span class="text-body-1">{{ orderedProduct.name }}</span>
              <span class="text-grey mt-1" style="font-weight: lighter">{{
                checkoutDetail.isHalf ? "Tikai tests" : "Pilns kurss"
              }}</span>
            </div>
            <div>
              <span class="text-no-wrap"
                ><span class="text-grey">1 x </span>{{ realPrice }}€</span
              >
            </div>
          </v-card-text>
          <v-divider class="mx-1" />
          <v-card-title class="d-flex justify-space-between px-1"
            ><span class="text-body-1">Starpsumma</span>
            <span class="text-body-1">{{ realPrice }}€</span></v-card-title
          >
          <v-divider class="mx-1" />
          <v-card-title class="d-flex justify-space-between px-1"
            ><b class="text-body-1 font-weight-bold">Summa</b>
            <b class="text-body-1 font-weight-bold"
              >{{ realPrice }}€</b
            ></v-card-title
          >
        </v-card>
        <about-payment v-if="$vuetify.display.mdAndUp" />
      </v-col>
    </v-row>
    <v-row v-if="!$vuetify.display.mdAndUp">
      <about-payment />
    </v-row>
  </v-form>
</template>

<script>
import { CheckoutAPI } from "@/api/apis/checkoutActions";
import AboutPayment from "@/components/Checkout/AboutPayment.vue";
import { ruleSet, ruleSetGen } from "@/helpers/rules";
import { UserData } from "@/storage/user";

export default {
  name: "DigitalCheckoutView",
  components: {
    AboutPayment,
  },
  data: () => {
    return {
      selectPayment: "direct",
      checkoutDetail: {
        isHalf: false,
      },
      orderedProduct: {},
      serverResponse: undefined,
      pickupPoints: [],
      user: UserData.User ?? {
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        personal_code: "",
      },
      rules: {
        firstname: ruleSetGen.text("Lūdzu ievadiet derīgu vārdu", true, 3),
        lastname: ruleSetGen.text("Lūdzu ievadiet derīgu uzvārdu", true, 3),
        email: ruleSet.email,
        phone_number: ruleSet.phoneNumber,
        personal_code: ruleSet.persCode,
        address: [() => true],
      },
      isLoading: false,
      isSent: false,
      error: undefined,
    };
  },
  computed: {
    realPrice() {
      if (this.checkoutDetail.isHalf) return this.orderedProduct.half_price;
      else return this.orderedProduct.base_price;
    },
    rulesValid() {
      function ruleValid(rules, val) {
        const results = [];

        for (const rule of rules) {
          if (results.length > 0) {
            break;
          }

          const result = rule(val);
          if (result === true) continue;
          if (result !== false && typeof result !== "string") {
            continue;
          }

          results.push(result || "");
        }

        return results.length === 0;
      }
      return (
        ruleValid(this.rules.firstname, this.user.firstname) &&
        ruleValid(this.rules.lastname, this.user.lastname) &&
        ruleValid(this.rules.email, this.user.email) &&
        ruleValid(this.rules.phone_number, this.user.phone_number) &&
        ruleValid(this.rules.personal_code, this.user.personal_code) &&
        ruleValid(this.rules.address, this.user.address)
      );
    },
    stepperState() {
      if (this.rulesValid) {
        return 3;
      } else {
        return 1;
      }
    },
    stepperHeaders() {
      return [
        {
          title: "Par pircēju",
          rule: this.rules,
          value: 1,
          isValid: this.rulesValid,
        },
        {
          title: "Piegādes informācija",
          rule: [() => true],
          value: 2,
          isValid: this.rulesValid,
        },
        {
          title: "Rēķins nosūtīts",
          rule: [() => true],
          value: 3,
          isValid: this.isSent,
        },
      ];
    },
  },
  mounted() {
    const selectedProduct = sessionStorage.getItem("digital-product");
    if (selectedProduct) {
      const { product, meta } = JSON.parse(selectedProduct);
      this.orderedProduct = product;
      this.checkoutDetail = meta;
      this.rules.address = ruleSetGen.text(
        "Lūdzu izvēlaties derīgu pakomāta adresi",
        false,
        5,
      );

      if (this.orderedProduct.is_license_card) {
        CheckoutAPI.pickupPoints()
          .then((res) => {
            this.pickupPoints = res.data;
          })
          .catch((v) => {
            this.error = v.message;
          });
      }
    } else {
      // TODO: Do other kinds of validation?
      const lastUrl = this.$router.options.history.state.back;
      if (lastUrl.includes(window.location.hostname)) {
        this.$router.goBack();
      } else {
        this.$router.push({ name: "home" });
      }
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    goToProfile() {
      this.$router.push({ name: "/profile/invoices" });
    },
    async order() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) return;
      this.isLoading = true;
      CheckoutAPI.orderCourse(
        this.orderedProduct.id,
        this.checkoutDetail.isHalf,
        this.user.firstname,
        this.user.lastname,
        this.user.email,
        this.user.phone_number,
        this.user.personal_code,
        this.user.address,
      )
        .then((v) => {
          this.serverResponse = v.data;
          this.isSent = true;
        })
        .catch((v) => {
          this.error = v.message;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    downloadInvoice() {
      this.isLoading = true;
      window.open(this.serverResponse.invoice_url, "_blank");
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.offset {
  margin-top: 100px;
}

.delivery-description {
  font-size: 14px;
  font-weight: initial;
  text-wrap: auto;
}
</style>
