<template>
  <div class="w-100">
    <VueCalendar :options="calendarOptions" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { VueCalendar } from "@zantixgroup/vue-calendar";
import { ArticlesAPI } from "@/api/apis/articlesActions";

export default defineComponent({
  components: { VueCalendar },
  data() {
    return {
      fetchedMonths: [],
      calendarOptions: {
        locale: "lv",
        showViewSelector: false,
        events: [],
        onEventClick: (e) => {
          this.$router.push({ path: `/articles/${e.id}` });
        },
        onNavigationChange: (date) => {
          this.getArticlesByEvents(date.toLocaleDateString("sv-SE"));
        },
      },
    };
  },
  mounted() {
    this.getArticlesByEvents(new Date().toLocaleDateString("sv-SE"));
  },
  methods: {
    getArticlesByEvents(date) {
      if (this.fetchedMonths.find((e) => e === date) === undefined) {
        const calendarEvents = [];
        setTimeout(async () => {
          return await ArticlesAPI.articlesCalendarFilter(date).then(
            (response) => {
              response.data.forEach((article) => {
                calendarEvents.push({
                  id: article.id,
                  title: article.title,
                  date: new Date(article.event_date),
                  color: "blue",
                });
              });
              this.calendarOptions.events =
                this.calendarOptions.events.concat(calendarEvents);
              this.fetchedMonths.push(date);
            },
          );
        }, 500);
      }
    },
  },
});
</script>
