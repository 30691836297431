<template>
  <footer>
    <v-img
      class="footer-waves"
      :src="require('@/assets/images/footer/waves.svg')"
    />
    <div class="footer-content d-flex justify-center flex-column">
      <div
        class="footer-upper-section d-flex justify-space-between align-center pb-2"
      >
        <v-img
          :src="require('@/assets/images/light_logo.svg')"
          max-width="200px"
        />
        <nav v-if="!UserData.IsCompanyUser">
          <router-link to="/about-us"> Par mums </router-link>
          <router-link to="/services"> Mūsu piedāvajumi </router-link>
          <router-link to="/learning-center"> Mācību centrs </router-link>
          <!--<router-link to="/"> Veikals </router-link>-->
        </nav>
        <a href="https://www.facebook.com/drosamdarbam.lv/" target="_blank"
          ><v-icon icon="mdi-facebook" size="44px"
        /></a>
      </div>
      <div
        class="footer-lower-section d-flex justify-space-between align-center mt-2"
      >
        <p>2024 © Guna BSK</p>
        <div class="privacy-section d-flex justify-center align-center">
          <router-link to="/">Privātuma politika</router-link>
          <router-link to="/">Datu aizsardzība</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { UserData } from "@/storage/user";

export default {
  name: "MainFooter",
  computed: {
    UserData() {
      return UserData;
    },
  },
};
</script>

<style scoped>
nav {
  display: flex;
  justify-content: center;
  position: relative;
  gap: 16px;
}

nav a {
  text-decoration: none;
  font-size: 16px;
  color: black;
  font-weight: normal;
  line-height: 1.5;
  transition: font-weight 0.05s ease;
  text-align: center;
}

.footer-upper-section a,
.privacy-section > a {
  cursor: pointer;
  color: white;
  text-decoration: none;
}

nav a:nth-child(1) {
  width: 85px;
}

nav a:nth-child(2) {
  width: 154px;
}

nav a:nth-child(3) {
  width: 118px;
}

nav a:nth-child(4) {
  width: 59px;
}

nav a:hover {
  color: #0d4b72;
}

nav a:active {
  color: #1ca7fd;
  font-weight: 600;
}

@media only screen and (width <= 1280px) {
  nav {
    gap: 10px;
  }

  nav a {
    font-size: 12px;
  }

  nav a:nth-child(1) {
    width: 64px;
  }

  nav a:nth-child(2) {
    width: 115px;
  }

  nav a:nth-child(3) {
    width: 89px;
  }

  nav a:nth-child(4) {
    width: 46px;
  }

  button {
    font-size: 12px !important;
    min-width: 0 !important;
    padding: 4px !important;
  }
}

@media only screen and (width <= 800px) {
  nav {
    display: none;
  }
}

footer {
  position: relative;
  height: 160px;
  width: 100%;
  margin-top: 150px;
  color: white;
}

.footer-waves {
  position: absolute;
  top: -26px;
  height: 32px;
  left: 0;
  right: 0;
}

footer::before {
  content: "";
  position: absolute;
  height: 100%;
  transform: translateX(-50%);
  width: 100vw;
  bottom: 0;
  left: 50%;
  background: #45b7fd;
}

.footer-content {
  z-index: 100;
  position: relative;
  height: 160px;
}

.footer-upper-section {
  border-bottom: 1px solid white;
}

.privacy-section {
  gap: 10px;
}

.privacy-section > a:hover {
  cursor: pointer;
  color: #eee;
}

@media only screen and (width <= 1980px) {
  .footer-waves {
    top: -24px;
  }
}

@media only screen and (width <= 1360px) {
  .footer-waves {
    top: -20px;
  }
}

@media only screen and (width <= 800px) {
  .footer-waves {
    top: -18px;
  }

  .footer-lower-section {
    flex-direction: column !important;
  }
}
</style>
