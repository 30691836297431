<template>
  <v-form ref="form" @submit.prevent>
    <v-card
      :max-width="cardSize.maxWidth"
      :width="cardSize.width"
      :class="cardSize.class"
    >
      <v-card-title>
        <h1 class="text-h5 text-sm-h4 white-space">Izraksta....</h1>
      </v-card-title>
      <v-card-subtitle class="text-subtitle-2 text-sm-subtitle-1 white-space"
        >Ja nekas nenotiek nospižat zemāk redzamo pogu!
      </v-card-subtitle>
      <v-card-actions class="d-flex flex-column">
        <v-btn
          block
          class="mx-0 my-1 py-5"
          variant="flat"
          color="primary"
          @click="logout"
          >Izrakstīties</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { UserData } from "@/storage/user";
export default {
  name: "LogoutView",
  inject: ["cardSize"],
  mounted() {
    this.logout();
  },
  methods: {
    goTo(route) {
      this.$router.push({ name: route });
    },
    logout() {
      UserData.wipeData();
      this.goTo("login");
    },
  },
};
</script>
