<template>
  <v-main>
    <slot />
  </v-main>
</template>

<script>
export default {
  name: "QuizLayout",
  // kaut zārka vāka galvu iespiež tam daunm kurš vue router taisot panāca ka lai paraditu lapu obligati vajag likt layoutu
  // vai ari to pašu mudaku kurš dokumentējot routeri nav piedavajis alternativu
  // uz abu kapakmeņiem uzmīst derētu
  data() {
    return {
      endUnixTimestamp: null,
    };
  },
};
</script>
