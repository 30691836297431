<template>
  <div class="container">
    <div class="header-container">
      <h1 class="header-title">SIA Guna BSK pakalpojumu saraksts</h1>
    </div>
    <v-expansion-panels>
      <v-expansion-panel>
        <template #title>
          <h3>Civilā aizsardzība</h3>
        </template>
        <template #text>
          <p>
            Civilās aizsardzības plāni jāizstrādā gan visu kategoriju
            paaugstinātas bīstamības objektiem, gan arī teritorijām.
          </p>
        </template>
      </v-expansion-panel>
      <v-expansion-panel>
        <template #title>
          <h3>
            Civilās aizsardzības plāns (CAP) paaugstinātas bīstamības objektiem
          </h3>
        </template>
        <template #text>
          <p>
            Civilās aizsardzības plānu izstrādā vietējas nozīmes jeb C
            kategorijas paaugstinātas bīstamības objektiem. Tātad gadījumos, ja
            uzņēmuma darbība atbilst Ministru kabineta noteikumu
            <b>Nr. 563</b> “Paaugstinātas bīstamības objektu apzināšanas un
            noteikšanas, kā arī civilās aizsardzības un katastrofas
            pārvaldīšanas plānošanas un īstenošanas kārtība” 1. pielikumā
            norādītajām vielām (bīstamībām) un to daudzumiem. Vietējas nozīmes
            paaugstinātas bīstamības objekti ir tādi, kuros uzglabā, piemēram:
          </p>
          <ul class="list">
            <li>amonija nitrātu saturošu komplekso mēslojumu (no 100 t);</li>
            <li>naftas gāzi (jau no 5 t) u.tml.</li>
          </ul>
          <p>
            Šobrīd faktiski daudziem uzņēmumiem, kuriem
            <b>piesārņojošas darbības atļaujas</b> izsniegtas pēdējās divās
            desmitgadēs, civilās aizsardzības plāni nav izstrādāti kaut arī
            normatīvie akti to bija paredzējuši. Šādiem uzņēmumiem Valsts vides
            dienests šobrīd pieprasa civilās aizsardzības plāna izstrādi kaut
            arī izmaiņas darbībās saistībā ar bīstamām ķīmiskajām vielām nav
            notikušas. Turklāt civilās aizsardzības plāns kā viens no soļiem
            jāizstrādā arī <u>A un B kategorijas</u> paaugstinātas bīstamības
            objektiem.
          </p>
        </template>
      </v-expansion-panel>
      <v-expansion-panel>
        <template #title>
          <h3>Civilās aizsardzības plāns (CAP) teritorijām</h3>
        </template>
        <template #text>
          <p>
            Latvijas pašvaldības un novadi civilās aizsardzības plānu izstrādā
            atbilstoši Ministru kabineta noteikumu <b>Nr. 658</b> “Noteikumi par
            civilās aizsardzības plānu struktūru un tajos iekļaujamo
            informāciju” prasībām. Ņemot vērā ģeopolitisko situāciju,
            Aizsardzības ministrija izstrādājusi grozījumus Ministru kabineta
            noteikumos <b>Nr. 658.</b> Šie grozījumi paredz, ka pašvaldībām jau
            izstrādātie civilās aizsardzības plānā (CAP) jāatjaunina, izvērstāk
            izplānojot pasākumus militāra iebrukuma gadījumā vai kara laikā.
            Līdz ar to civilās aizsardzības plāna militārajā sadaļā jāiekļauj
            tādu informācija kā:
          </p>
          <ul class="list">
            <li>agrīnā brīdināšana,</li>
            <li>situācijas monitorings un iedzīvotāju informēšana,</li>
            <li>mobilitātes un pretmobilitātes pasākumi,</li>
            <li>
              degvielas, kurināmā, dzeramā ūdens un pārtikas rezervju
              iesaistīšana,
            </li>
            <li>patvertnēm piemēroto būvju izmantošana,</li>
            <li>mobilo energoapgādes avotu iesaistīšana,</li>
            <li>evakuēto personu uzņemšana pašvaldības teritorijā,</li>
            <li>pašvaldības institūciju kritisko funkciju noteikšana u.tml.</li>
          </ul>
        </template>
      </v-expansion-panel>
      <v-expansion-panel>
        <template #title>
          <h3>Darba aizsardzība</h3>
        </template>
        <template #text>
          <ul class="list">
            <li>Darba aizsardzības organizatoriskās struktūras izveidošana.</li>
            <li>Darba vides riska faktoru novērtēšanas metodikas izstrāde.</li>
            <li>Darba vides iekšējās uzraudzības plāna izstrādāšana.</li>
            <li>Instrukciju pārskatīšana, uzlabošana, izstrāde.</li>
            <li>Darba aizsardzības dokumentu sagatavošana.</li>
            <li>Jauno darba aizsardzības normatīvo aktu prasību ieviešana.</li>
            <li>Darbinieku instruēšana.</li>
            <li>Darba vides risku izvērtēšana.</li>
            <li>Preventīvo pasākumu plāna izstrāde.</li>
            <li>Veselības pārbaužu sarakstu sastādīšana.</li>
            <li>Obligāto veselības pārbaužu karšu sastādīšana.</li>
            <li>Individuālo aizsardzības līdzekļu noteikšana un piegāde.</li>
            <li>Tehniskās apkopes plāna izstrāde iekārtām.</li>
            <li>Darba vides iekšējās uzraudzības plāna izstrādāšana.</li>
            <li>Amatu Aprakstu izstrāde.</li>
            <li>Darba līgumu izstrāde.</li>
          </ul>
          <p>Darbinieku instruktāžas klātienē un e-apmācības</p>
        </template>
      </v-expansion-panel>
      <v-expansion-panel>
        <template #title>
          <h3>Izglītojamo drošība izglītības iestādēs</h3>
        </template>
        <template #text>
          <ul class="list">
            <li>
              Sagatavot nepieciešamos dokumentus, organizēt atbilstošo sistēmu
              pēc MK noteikumiem Nr.474 no 22.08.2023; “Kārtība, kādā
              nodrošināma izglītojamo profilaktiskā veselības aprūpe, pirmā
              palīdzība un drošība izglītības iestādēs un to organizētajos
              pasākumos”
            </li>
            <li>
              Rīkojumu un nepieciešamo dokumentu izstrāde atbildīgajiem par
              drošību ekskursijās, pārgājienos, masu pasākumos un sporta
              sacensībās, operatīvo dienestu izsaukšanu, iekšējās kārtības
              noteikumiem, drošības instrukcijām un evakuācijas plānu.
              Atbilstoši izglītības iestādes vajadzībām un vietējiem apstākļiem
              izstrādā šādus drošības noteikumus:(sākumskolas klasēs pedagogiem
              klāt arī uzdevumi instrukciju vieglākai apguvei) izglītojamiem
              par:
              <ul class="nested-list">
                <li>
                  drošību telpās, kurās ir iekārtas un vielas, kas var apdraudēt
                  izglītojamo drošību un veselību;
                </li>
                <li>ugunsdrošību un elektrodrošību;</li>
                <li>pirmās palīdzības sniegšanu;</li>
                <li>drošību mācību laikā un to starplaikos;</li>
                <li>
                  drošību izglītības iestādes organizētajos vai atbalstītajos
                  pasākumos;
                </li>
                <li>
                  drošību attālināto mācību laikā atbilstoši normatīvajam aktam
                  par attālināto mācību organizēšanas un īstenošanas kārtību;
                </li>
                <li>
                  rīcību situācijās, kuras iepriekš nevar paredzēt (piemēram, ja
                  izcēlies ugunsgrēks, telpās jūtama gāzes smaka, plūdi);
                </li>
                <li>ceļu satiksmes drošību;</li>
                <li>drošību uz ūdens un ledus;</li>
                <li>personas higiēnu un darba higiēnu;</li>
                <li>drošību, veicot praktiskos un laboratorijas darbus;</li>
                <li>atbildību par drošības noteikumu neievērošanu;</li>
                <li>citus izglītības iestādes darbībai būtiskus jautājumus.</li>
              </ul>
            </li>
          </ul>
        </template>
      </v-expansion-panel>
      <v-expansion-panel>
        <template #title>
          <h3>Kvalitātes vadības sistēma ISO 9001</h3>
        </template>
        <template #text>
          <ul class="list">
            <li>Integrētā menedžmenta sistēmas izveide;</li>
            <li>Uzņēmuma politikas izveide;</li>
            <li>Organigrammas izstrāde;</li>
            <li>Biznesa risku vērtēšana</li>
            <li>Korektūru un profilakses pasākumi (kļūdu, brāķa vadība);</li>
            <li>Apmācības pasākumi, konsultēšana;</li>
            <li>
              Finansu menedžments (budžets, rēķinu sistēma, izmaksu kontrole,
              likviditāte);
            </li>
            <li>Pārbaužu process ( vadības pārbaudes, internie auditi);</li>
            <li>Riska/ krīzes menedžments;</li>
            <li>Dokumentāciju un datu vadība;</li>
            <li>Mārketings (tirgus izpēte);</li>
            <li>
              Sistēmas izveide un dokumentu sastādīšana pārdošanai, sadalei,
              transportam;
            </li>
            <li>Produktu attīstības veicināšana;</li>
            <li>Sagādes sistēmas procesi un dokumentu sagatavošana;</li>
            <li>Ražošanas plānošanas un procesu dokumentācijas izstrāde;</li>
            <li>Noliktavu vadība un dokumentācija;</li>
            <li>Identifikācija un izsekojamība.</li>
          </ul>
        </template>
      </v-expansion-panel>
      <v-expansion-panel>
        <template #title>
          <h3>Pārtikas nekaitīguma nodrošināšanas sistēma</h3>
        </template>
        <template #text>
          <p>
            Drošas un nekaitīgas pārtikas aprite, kura ietver pārtikas aprites
            posma uzraudzību un analīzi, nosaka posmus pārtikas apritē, kuri ir
            kritiski (bīstami) pārtikas drošībai un nekaitīgumam un ievieš
            efektīvas kontroles un uzraudzības procedūras šajos posmos. HACCP,
            higiēnas prasības pārtikas apritē
          </p>
        </template>
      </v-expansion-panel>
      <v-expansion-panel>
        <template #title>
          <h3>Ugunsdrošība</h3>
        </template>
        <template #text>
          <ul class="list">
            <li>Evakuācijas plānu izstrāde un uzstādīšana.</li>
            <li>
              Ugunsdrošības instrukciju sagatavošana un darbinieku instruēšana.
            </li>
            <li>"Rīcības plāns ugunsgrēka gadījumā" izstrāde.</li>
            <li>Drošības zīmju noteikšana, piegāde un uzstādīšana iestādē.</li>
            <li>
              Ugunsdzēšamo aparātu pārbaude un dzēstspējas aprēķins u.c.
              dokumentācijas veikšana.
            </li>
            <p>Klātienē un e-apmācības</p>
          </ul>
        </template>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: "ServicesView",
};
</script>

<style scoped>
.container {
  width: 100%;
  margin-top: 150px;
}

b,
h3 {
  color: #188ed7;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title {
  flex: 1;
  font-size: 56px;
}

.header-image {
  flex: 1;
  height: 100px;
}

.list {
  margin-left: 30px;
}

.nested-list {
  margin-left: 50px;
}

.divider {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media only screen and (width <= 900px) {
  .header-title {
    font-size: 36px;
    text-align: center;
  }

  .header-image {
    display: none;
  }
}
</style>
