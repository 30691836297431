<template>
  <v-main class="d-flex flex-column justify-space-between">
    <MainNavigationBar />
    <error-dialog />
    <div class="d-flex content">
      <v-card class="sidebar-container d-flex flex-column">
        <span class="title">Par mani</span>
        <router-link class="menu-item" to="/profile"
          >Vispārīgā informācija</router-link
        >
        <router-link class="menu-item" to="/profile/invoices"
          >Apmaksu vēsture</router-link
        >
        <span class="title">Kursi</span>
        <router-link class="menu-item" to="/profile/courses"
          >Esošie kursi</router-link
        >
        <router-link class="menu-item" to="/profile/completed_courses"
          >Pabeigtie kursi</router-link
        >
        <span class="title">Darbības</span>
        <a v-if="hasAdminView" class="menu-item" @click="toAdmin"
          >Administratora panelis</a
        >
        <router-link class="menu-item menu-item-logout" to="/logout"
          >Atslēgties</router-link
        >
      </v-card>
      <slot v-if="isLoggedIn" />
    </div>
    <MainFooter />
  </v-main>
</template>

<script>
import MainFooter from "@/components/MainFooter.vue";
import MainNavigationBar from "@/components/MainNavigationBar.vue";
import { UserData } from "@/storage/user";
import ErrorDialog from "@/components/ErrorDialog.vue";

export default {
  name: "MainLayout",
  components: {
    MainNavigationBar,
    MainFooter,
    ErrorDialog,
  },
  data() {
    return {
      hasAdminView: UserData.hasPermission("view.admin"),
      isLoggedIn: UserData.IsLoggedIn,
    };
  },
  created() {
    if (!UserData.IsLoggedIn) {
      this.$router.push({ name: "login" });
    }
  },
  methods: {
    toAdmin() {
      window.location.replace("/admin");
    },
  },
};
</script>

<style scoped>
main {
  min-height: 100vh !important;
  height: 100% !important;
}

/* sidebar */
.content {
  margin-top: 150px;
  gap: 20px;
}

.sidebar-container {
  max-width: 370px;
  border-radius: 15px;
  padding: 26px;
  width: 100%;
  height: fit-content;
  gap: 8px;
}

.sidebar-container a.router-link-active {
  background-color: #e8f6ff;
  border-radius: 8px;
}

.sidebar-container > a {
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  color: #000;
  text-decoration: unset;
  cursor: pointer;
}

.sidebar-container > a:hover {
  background-color: #e8f6ff;
  transition: 0.3s;
}

.menu-item-logout {
  color: red !important;
}

.title {
  font-size: 18px;
  font-weight: 700;
}

@media only screen and (width <= 800px) {
  .content {
    flex-direction: column;
    margin-top: 100px;
  }

  .sidebar-container {
    max-width: initial;
  }
}
</style>
