<template>
  <div v-if="!loading" class="container">
    <v-img
      class="article-caricature"
      :src="article.image"
      alt="article-caricature"
      cover
    />
    <div class="article-container">
      <div class="article-info">
        <div>
          <h1 class="article-title">{{ article.title }}</h1>
        </div>
        <div v-if="article.event_date" class="article-info-cards">
          <AttributeCard
            icon="mdi-calendar-month-outline"
            :text="
              article.event_date.substring(0, article.event_date.length - 3)
            "
          />
        </div>
      </div>
      <v-divider />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="article-description" v-html="article.text" />
    </div>
  </div>
  <div v-else class="loading">
    <v-progress-circular
      indeterminate
      :size="128"
      :width="12"
      color="primary"
    ></v-progress-circular>
  </div>
  <ArticlesSection />
</template>

<script>
import { ArticlesAPI } from "@/api/apis/articlesActions";
import ArticlesSection from "@/components/HomePage/ArticlesSection.vue";
import AttributeCard from "@/components/AttributeCard.vue";

export default {
  name: "ArticleView",
  components: { AttributeCard, ArticlesSection },
  data() {
    return {
      loading: true,
      article: {},
    };
  },
  mounted() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.loading = true;
        this.getArticle().then(() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        });
      },
      { immediate: true },
    );
  },
  methods: {
    async getArticle() {
      return await ArticlesAPI.article(this.$route.params.id).then(
        (response) => {
          this.article = response.data;
          this.loading = false;
        },
      );
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  margin-inline: auto;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.article-caricature {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 0;
  height: 250px;
  width: 100%;
  margin: 150px 0 0;
}

.article-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 35px;
  background-color: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.article-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.article-info-cards {
  display: flex;
  gap: 10px;
}

.article-title {
  font-weight: 500;
}

.custom-tooltip :deep(.v-overlay__content) {
  padding: 11px 16px !important;
}

.material-card :deep(i) {
  font-size: 48px !important;
}

.material-card :deep(.v-avatar.v-avatar--density-default) {
  height: initial !important;
  width: initial !important;
}

@media only screen and (width <= 830px) {
  .container {
    width: 100% !important;
  }

  .article-container {
    padding: 10px !important;
  }

  .article-info-cards {
    flex-direction: column;
  }
}
</style>
