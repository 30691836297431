<template>
  <v-card
    class="rounded-lg d-flex justify-space-between flex-column course-container"
    max-width="280"
    height="485"
    hover
  >
    <v-img
      class="align-end text-white course-image"
      min-height="225"
      max-height="225"
      :src="course.image"
      cover
    >
    </v-img>
    <v-card-title class="courses-section-card-title">{{
      course.name
    }}</v-card-title>

    <v-card-text>
      <p class="courses-section-card-paragraph">
        {{
          course.description.substring(0, 70).replace(/<[^>]*>?/gm, "") + "..."
        }}
      </p>
    </v-card-text>

    <v-container class="d-flex align-center">
      <v-card-actions>
        <v-btn
          variant="flat"
          color="primary"
          size="large"
          class="text-lowercase lg"
          @click="toCourse()"
        >
          Skatīt
        </v-btn>
      </v-card-actions>
      <!--      <v-container>-->
      <!--        <v-icon dark> mdi-account-multiple-outline </v-icon>-->
      <!--        {{ course.enrolled_users }}-->
      <!--      </v-container>-->
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    course: {
      type: Object,
      default: null,
    },
  },
  methods: {
    toCourse() {
      this.$router.push(`/course/${this.course.id}`);
    },
  },
};
</script>

<style scoped>
.courses-section-card-title {
  font-size: 25px;
  color: #262626;
  font-weight: 500;
  line-height: 110%;
  white-space: normal;
  display: inline-block;
  word-wrap: break-word;
}

.courses-section-card-paragraph {
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #262626;
}

.course-container {
  width: 100% !important;
}

@media only screen and (width <= 830px) {
  .course-image {
    object-fit: cover;
    max-width: 100% !important;
    max-height: 200px !important;
    height: 100% !important;
    margin: unset !important;
    border-bottom-left-radius: unset !important;
    border-bottom-right-radius: unset !important;
  }

  .course-container {
    flex-direction: column;
    height: auto !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}
</style>
