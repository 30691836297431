import { log } from "./debugLog";

let showDialog: (
  title: string,
  message: string,
  details?: string,
  isPermanent?: boolean,
) => void;

export function register(dialog: any) {
  // eslint-disable-next-line no-extra-boolean-cast
  if (!!showDialog)
    log("ErrorDialog", "Attempt to overwrite dialog. Allowing", 1);
  showDialog = dialog;
}

const defaults = {
  title: "Ir radusies kļūda",
  details: undefined,
  permament: false,
};

export function error(params: {
  message: string;
  title?: string;
  details?: string;
  permanent?: boolean;
}) {
  const conf = { ...defaults, ...params };
  if (showDialog) {
    showDialog(conf.title, conf.message, conf.details, conf.permament);
  } else {
    log("ErrorDialog", "No dialog initialized", 1);
  }
}
