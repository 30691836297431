<template>
  <div class="content">
    <h1>Kursu pasūtījumi</h1>
    <TableContent
      :table-info="userTableInfo"
      name="user_courses"
    ></TableContent>
  </div>
</template>

<script>
import TableContent from "@/Admin/components/TableContent.vue";
import AllowCourseAccessAction from "@/Admin/components/form_actions/AllowCourseAccessAction.vue";

export default {
  name: "UserCourseListView",
  components: { TableContent },
  data: () => ({
    userTableInfo: {
      headers: [
        {
          title: "Rēķina nr.",
          align: "start",
          key: "invoice_number",
        },
        {
          title: "Kurss",
          align: "start",
          key: "course.name",
        },
        {
          title: "Galējā cena",
          align: "start",
          key: "invoice_total",
        },
        {
          title: "Vārds",
          align: "start",
          key: "user.firstname",
        },
        {
          title: "Uzvārds",
          align: "start",
          key: "user.lastname",
        },
        {
          title: "E-pasts",
          align: "start",
          key: "user.email",
        },
        {
          title: "Kurss",
          align: "start",
          key: "course.name",
        },
        {
          title: "Piekļuve pie kursa",
          align: "start",
          key: "order_completed",
        },
        {
          title: "Darbības",
          align: "start",
          sortable: false,
          key: "actions",
        },
      ],
      actions: [AllowCourseAccessAction],
    },
  }),
};
</script>

<style scoped>
.content {
  width: 100%;
}
</style>
