import { API, backendErrorResponse } from "../apiActions";
import config from "../config";
import { privateUserInfo } from "@/helpers/types";

type loginResponse = {
  access_token: string;
  data: privateUserInfo;
};

type registerResponse = {
  data: {
    user_id: number;
    email: string;
  };
};

type meResponse = {
  data: privateUserInfo;
};

type securityCheckResponse = {
  data: {
    user_id: number;
    security_code: string;
  };
};

export class AuthAPI extends API {
  public static login(email: string, password: string) {
    return this.__withResponseCodes<loginResponse>(
      fetch(`${config.baseURL}/login`, {
        method: "POST",
        headers: config.headers,
        body: JSON.stringify({ email, password }),
      }),
      false,
      "/login",
    );
  }

  public static loginWithCode(code: string) {
    return this.__successOnly<loginResponse>(
      fetch(`${config.baseURL}/company_user_login`, {
        method: "POST",
        headers: config.headers,
        body: JSON.stringify({ access_code: code }),
      }),
      "/company_user_login",
    );
  }

  public static register(params: {
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    password_confirmation: string;
  }) {
    return this.__successOnly<registerResponse>(
      fetch(`${config.baseURL}/register`, {
        method: "POST",
        headers: config.headers,
        body: JSON.stringify(params),
      }),
      "/register",
    );
  }

  public static resendValidationEmail(id: number, email: string) {
    return this.__successOnly<never>(
      fetch(`${config.baseURL}/email/resend`, {
        method: "POST",
        headers: config.headers,
        body: JSON.stringify({ email, user_id: id }),
      }),
      "/email/resend",
    );
  }

  public static resetPassword(email: string) {
    return this.__successOnly<loginResponse>(
      fetch(`${config.baseURL}/forgot_password/reset`, {
        method: "POST",
        headers: config.headers,
        body: JSON.stringify({ email }),
      }),
      "/forgot_password/reset",
    );
  }

  public static resetPasswordSecurityCheck(id: number, code: string) {
    return this.__successOnly<securityCheckResponse>(
      fetch(`${config.baseURL}/forgot_password/security_check`, {
        method: "POST",
        headers: config.headers,
        body: JSON.stringify({ user_id: id, code }),
      }),
      "/forgot_password/security_check",
    );
  }

  public static resetPasswordConfirm(
    id: number,
    securityCode: string,
    password: string,
    passwordConfirmation: string,
  ) {
    return this.__withResponseCodes<backendErrorResponse>(
      fetch(`${config.baseURL}/forgot_password/reset_password/${id}`, {
        method: "POST",
        headers: config.headers,
        body: JSON.stringify({
          security_code: securityCode,
          password,
          password_confirmation: passwordConfirmation,
        }),
      }),
      false,
      `/forgot_password/reset_password/${id}`,
    );
  }

  public static logout() {
    return this.__successOnly<boolean>(
      fetch(`${config.baseURL}/logout`, {
        method: "GET",
        headers: config.headers,
      }),
      "/logout",
    );
  }

  public static me() {
    return this.__successOnly<meResponse>(
      fetch(`${config.baseURL}/me`, {
        method: "GET",
        headers: config.headers,
      }),
      "/me",
    );
  }
}
