import { log } from "../helpers/debugLog";
import { error } from "@/helpers/errorDialog";

export type backendErrorResponse = {
  message: string;
  code: number;
  ok: boolean;
  errors: {
    [key: string]: string[];
  };
};

type rawResponse<T> = {
  response: T & backendErrorResponse;
  code: number;
  ok: boolean;
};

export class API {
  protected static __withResponseCodes<T>(
    networkPromise: Promise<Response>,
    throwOnFail: true,
    path?: string,
    returnStatus?: boolean,
  ): Promise<T>;

  protected static __withResponseCodes<T>(
    networkPromise: Promise<Response>,
    throwOnFail: false,
    path?: string,
    returnStatus?: boolean,
  ): Promise<rawResponse<T>>;

  protected static __withResponseCodes<T>(
    networkPromise: Promise<Response>,
    throwOnFail?: boolean,
    path?: string,
  ): Promise<rawResponse<T> | T> {
    return new Promise((resolve, reject) => {
      this.withPopup(networkPromise)
        .then(async (res) => {
          log(`Auth ${path}`, res, 6);
          const response: T & backendErrorResponse = await res.json();
          if (throwOnFail === true) {
            if (res.ok) {
              return resolve(response);
            } else {
              response.code = res.status;
              response.ok = res.ok;
              reject(response);
            }
          } else {
            return resolve({ response, code: res.status, ok: res.ok });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  protected static __successOnly<T>(handle: Promise<Response>, path?: string) {
    return this.__withResponseCodes<T>(handle, true, path);
  }

  public static withPopup(
    handle: Promise<Response>,
    throwOnAll = false,
  ): Promise<Response> {
    return new Promise((resolve, reject) => {
      handle
        .then(async (res) => {
          const usableRes = await res.clone();
          const response: backendErrorResponse = await usableRes.json();
          if (res.status >= 500) {
            error({
              message:
                "Serveris nespēj apstrādāt Jūsu pieprasījumu. Lūdzu mēģiniet vēlāk",
              details: response.message,
            });
          } else if (!res.ok && throwOnAll) {
            error({ message: response.message });
          }
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
}
