import GeneralStore from "@/store/modules/general";
import { privateUserInfo } from "@/helpers/types";
import { AuthAPI } from "@/api/apis/authActions";
import { updateHeaders, updateTempHeaders } from "@/api/config";
import { log } from "@/helpers/debugLog";

export class UserData {
  private static initialized: boolean = false;

  public static initialize() {
    if (this.initialized) return;
    const storage = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const tempToken = localStorage.getItem("temp-token");
    if (storage && storage !== "undefined") {
      const user: privateUserInfo = JSON.parse(storage);
      if (token !== undefined && token && user !== undefined) {
        log("UserData", "Is logged in", 3);
        this.setUser(user);
        updateHeaders(token);
        this.refetchUserData();
      } else if (!token && tempToken) {
        log("UserData", "Is company user", 3);
        updateTempHeaders(tempToken);
      } else {
        this.wipeData();
      }
    } else if (!token && tempToken) {
      log("UserData", "Is company user", 3);
      updateTempHeaders(tempToken);
    } else {
      this.wipeData();
    }
    this.initialized = true;
  }

  private static saveState() {
    log("UserData", "Saving state", 3);
    if (this.IsLoggedIn) {
      localStorage.setItem("user", JSON.stringify(this.user));
    } else {
      localStorage.setItem("user", JSON.stringify(undefined));
    }
  }

  public static wipeData() {
    log("UserData", "Wiping user data", 3);
    this.user = undefined;
    this.IsLoggedIn = false;
    localStorage.removeItem("token");
    localStorage.removeItem("temp-token");
    this.saveState();
  }

  public static setUser(user: privateUserInfo) {
    this.user = user;
    this.IsLoggedIn = true;
    this.saveState();
  }

  private static refetchUserData() {
    AuthAPI.me()
      .then((user) => {
        this.setUser(user.data);
      })
      .catch(() => {
        if (this.IsCompanyUser) return;
        this.wipeData();
      });
  }

  public static hasPermission(permission: string): boolean {
    if (!this.user) return false;
    return this.user.permissions.includes(permission);
  }

  private static get user(): privateUserInfo | undefined {
    return GeneralStore.getValue(["user"]);
  }

  private static set user(user: privateUserInfo | undefined) {
    GeneralStore.setValue(["user"], user);
  }

  static get User(): privateUserInfo {
    return GeneralStore.getValue(["user"]);
  }

  static get Id(): boolean {
    return GeneralStore.getValue(["user", "id"]);
  }

  private static set IsLoggedIn(value: boolean) {
    GeneralStore.setValue(["isLoggedIn", "val"], value);
  }

  static get IsCompanyUser(): boolean {
    return localStorage.getItem("temp-token") !== null;
  }

  static get IsLoggedIn(): boolean {
    if (!this.initialized) log("UserData", "Call to IsLoggedIn before init", 5);
    return GeneralStore.getValue(["isLoggedIn", "val"]) || this.IsCompanyUser;
  }

  static get Role(): number | undefined {
    return GeneralStore.getValue(["user", "role", "id"]);
  }

  static get Avatar(): string | undefined {
    return GeneralStore.getValue(["user", "avatar"]);
  }

  static get Firstname(): string | undefined {
    return GeneralStore.getValue(["user", "firstname"]);
  }

  static get Lastname(): string | undefined {
    return GeneralStore.getValue(["user", "lastname"]);
  }

  static get Email(): string | undefined {
    return GeneralStore.getValue(["user", "email"]);
  }

  static get PhoneNumber(): string | undefined {
    return GeneralStore.getValue(["user", "phone_number"]);
  }

  static get PersonalCode(): string | undefined {
    return GeneralStore.getValue(["user", "personal_code"]);
  }

  static get Permissions(): string[] | undefined {
    return GeneralStore.getValue(["user", "permissions"]);
  }
}
