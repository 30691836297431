import { API } from "../apiActions";
import config from "../config";
import { publicUserInfo } from "@/helpers/types";
import { StatusCodes } from "@/helpers/enums";
type checkoutResponse = {
  data: {
    id: number;
    number: string;
    invoice_url: string;
    user: publicUserInfo;
    type: {
      id: number;
      name: string;
    };
    status: StatusCodes;
    created_at: string;
  };
};

export class CheckoutAPI extends API {
  public static orderCourse(
    id: number,
    restricted: boolean,
    firstname: string,
    lastname: string,
    email: string,
    phoneNumber: string | null,
    personalCode: string,
    address: string,
  ) {
    return this.__successOnly<checkoutResponse>(
      fetch(`${config.baseURL}/invoices`, {
        method: "POST",
        headers: config.headers,
        body: JSON.stringify({
          course_id: id,
          type_id: 1,
          restricted_course: restricted,
          firstname,
          lastname,
          email,
          personal_code: personalCode,
          address,
          ...(phoneNumber && { phone_number: phoneNumber }),
        }),
      }),
      "/invoices",
    );
  }

  public static pickupPoints() {
    return this.__successOnly<checkoutResponse>(
      fetch(`${config.baseURL}/pickup_points`, {
        method: "GET",
        headers: config.headers,
      }),
      "/pickup_points",
    );
  }
}
