<template>
  <v-form ref="form" @submit.prevent>
    <v-card
      :max-width="cardSize.maxWidth"
      :width="cardSize.width"
      :class="cardSize.class"
    >
      <v-card-title>
        <h1 class="text-h5 text-sm-h4 white-space">Apstiprina...</h1>
      </v-card-title>
      <v-card-subtitle class="text-subtitle-2 text-sm-subtitle-1 white-space"
        >Ja nekas nenotiek, noklikšķiniet zemāk redzmo pogu
      </v-card-subtitle>
      <v-card-text>
        <v-alert v-if="error" type="error" class="mt-3">
          {{ error }}
        </v-alert>
      </v-card-text>

      <v-card-actions class="d-flex flex-column">
        <v-btn
          block
          class="mx-0 my-1 py-5"
          variant="flat"
          color="primary"
          :disabled="isLoading"
          :loading="isLoading"
          @click="confirmToken"
          >Apstiprināt</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { updateHeaders } from "@/api/config";
import { AuthAPI } from "@/api/apis/authActions";
import { UserData } from "@/storage/user";
export default {
  name: "ValidateTokenView",
  inject: ["cardSize"],
  data: () => {
    return {
      error: "",
      isLoading: false,
    };
  },
  methods: {
    goTo(route) {
      this.$router.push({ name: route });
    },
    created() {
      if (!this.$route.query.token) {
        this.$router.push({ name: "login" });
      }
    },
    mounted() {
      if (this.$route.query.token !== undefined) {
        this.confirmToken();
      }
    },
    confirmToken() {
      this.isLoading = true;
      updateHeaders(this.$route.query.token);
      AuthAPI.me()
        .then((user) => {
          localStorage.setItem("token", this.$route.query.token);
          UserData.setUser(user.data);
          this.goTo("home");
        })
        .catch((err) => {
          let errStr = "";
          for (const key in err.errors) {
            errStr += err.errors[key][0] + "\n";
          }
          if (!errStr)
            errStr = "Ir radusies kļūda, lūdzu atgriežaties uz pieslēgšanos!";
          this.error = errStr;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
