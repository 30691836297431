<template>
  <header ref="navigation-bar">
    <div class="navigation-bar-content">
      <NavigationBarLogo />
      <nav v-if="!UserData.IsCompanyUser">
        <router-link to="/about-us">Par mums</router-link>
        <router-link to="/services">Mūsu piedāvajumi</router-link>
        <router-link to="/learning-center">Mācību centrs</router-link>
        <!--<router-link to="/"> Veikals </router-link>-->
      </nav>
      <div v-if="!isLoggedIn" class="button-container">
        <v-btn
          variant="flat"
          color="primary"
          class="text-lowercase"
          @click="goTo('register')"
        >
          Reģistrēties
        </v-btn>
        <v-btn
          variant="plain"
          class="text-lowercase ml-2"
          @click="goTo('login')"
        >
          Pieslēgties
        </v-btn>
      </div>
      <div v-else-if="IsCompanyUser()" class="button-container">
        <v-btn variant="plain" class="text-lowercase ml-2" @click="logout">
          Atslēgties
        </v-btn>
      </div>
      <div v-else class="button-container">
        <v-btn
          variant="text"
          icon="true"
          height="48"
          width="48"
          class="profile-icon"
          @click="goTo('profile')"
        >
          <router-link to="/profile">
            <v-avatar
              size="48"
              class="profile-icon"
              :image="UserData.Avatar"
              cover
            ></v-avatar>
          </router-link>
        </v-btn>
      </div>
      <v-menu transition="slide-x-transition">
        <template #activator="{ props }">
          <v-btn color="primary" v-bind="props" icon="mdi-menu" class="menu">
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title
              ><router-link class="menu-item" to="/profile"
                >Profils</router-link
              ></v-list-item-title
            >
            <v-list-item-title
              ><router-link class="menu-item" to="/about-us"
                >Par mums</router-link
              ></v-list-item-title
            >
            <v-list-item-title
              ><router-link class="menu-item" to="/services"
                >Mūsu piedāvajumi</router-link
              ></v-list-item-title
            >
            <v-list-item-title
              ><router-link class="menu-item" to="/learning-center"
                >Mācību centrs</router-link
              ></v-list-item-title
            >
            <!--            <v-list-item-title-->
            <!--              ><router-link class="menu-item" to="/shop"-->
            <!--                >Veikals</router-link-->
            <!--              ></v-list-item-title-->
            <!--            >-->
            <template v-if="!isLoggedIn">
              <v-list-item-title
                ><router-link class="menu-item" to="/login"
                  >Pieslēgties</router-link
                ></v-list-item-title
              >
              <v-list-item-title
                ><router-link class="menu-item" to="/register"
                  >Reģistrēties</router-link
                ></v-list-item-title
              >
            </template>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </header>
</template>

<script>
import NavigationBarLogo from "@/components/NavigationBarLogo.vue";
import { UserData } from "@/storage/user";

export default {
  name: "MainNavigationBar",
  components: {
    NavigationBarLogo,
  },
  data() {
    return {
      route: this.$route.name,
    };
  },
  computed: {
    UserData() {
      return UserData;
    },
    isLoggedIn() {
      return UserData.IsLoggedIn;
    },
  },
  mounted() {
    const navbar = this.$refs["navigation-bar"];
    window.addEventListener("scroll", function () {
      if (window.scrollY < 50) {
        // Adjust this value based on when you want the background color to change
        navbar.style.backgroundColor = "transparent";
      } else {
        navbar.style.backgroundColor = "white"; // Change this to your desired background color
      }
    });
  },
  methods: {
    goTo(name) {
      this.$router.push({ name });
    },
    IsCompanyUser() {
      return UserData.IsCompanyUser;
    },
    logout() {
      UserData.wipeData();
      this.goTo("login");
    },
  },
};
</script>

<style scoped>
header {
  position: fixed;
  height: 76px;
  transition: 0.2s all;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  width: 100vw;
  top: 0;
  left: 0;
}

.navigation-bar-content {
  max-width: min(80vw, 1453px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

nav {
  display: flex;
  justify-content: center;
  position: relative;
  gap: 16px;
}

.menu {
  display: none;
}

nav a {
  text-decoration: none;
  font-size: 16px;
  color: black;
  font-weight: normal;
  line-height: 1.5;
  transition: font-weight 0.05s ease;
  text-align: center;
}

nav a:nth-child(1) {
  width: 85px;
}

nav a:nth-child(2) {
  width: 154px;
}

nav a:nth-child(3) {
  width: 118px;
}

nav a:nth-child(4) {
  width: 59px;
}

nav a:hover {
  color: #0d4b72;
}

nav a:active {
  color: #1ca7fd;
  font-weight: 600;
}

nav a.router-link-active {
  color: #1ca7fd;
}

.profile-icon {
  border-radius: 50%;
}

@media only screen and (width <= 1280px) {
  nav {
    gap: 10px;
  }

  nav a {
    font-size: 12px;
  }

  nav a:nth-child(1) {
    width: 64px;
  }

  nav a:nth-child(2) {
    width: 115px;
  }

  nav a:nth-child(3) {
    width: 89px;
  }

  nav a:nth-child(4) {
    width: 46px;
  }

  button {
    font-size: 12px !important;
    min-width: 0 !important;
    padding: 4px !important;
  }

  .navigation-bar-content {
    max-width: 94vw;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (width <= 800px) {
  nav,
  .button-container {
    display: none;
  }

  .menu {
    display: initial;
  }

  .menu-item {
    text-decoration: none;
    font-size: 18px;
    color: black;
    font-weight: normal;
    line-height: 40px;
  }
}
</style>
