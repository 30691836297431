<template>
  <div class="articles-section-container">
    <div
      class="articles-section-content d-flex flex-column align-center justify-space-between"
    >
      <h2 class="section-title mt-10">Uzzini par mūsu aktualitātēm</h2>
      <p>
        Šajā kalendārā ir pārskatāmi izklāstīti visi drīzesošie ieplānotie
        pasākumi
      </p>
      <v-container class="w-100 articles-section-cards-container mt-10 mb-10">
        <v-row>
          <v-col
            v-for="(card, index) in cards"
            :key="index"
            cols="12"
            md="12"
            lg="6"
          >
            <ArticleCard :article="card" />
          </v-col>
        </v-row>
      </v-container>
      <v-btn
        color="primary"
        class="text-lowercase"
        @click="$router.push('/articles')"
        >Skatīt visas aktualitātes</v-btn
      >
    </div>
  </div>
</template>
<script>
import config from "@/api/config";
import ArticleCard from "@/components/ArticleCard.vue";

export default {
  components: { ArticleCard },
  data() {
    return {
      cards: [],
    };
  },
  async created() {
    await fetch(`${config.baseURL}/articles?page=1&pagination=4`, {
      method: "GET",
      headers: config.headers,
    })
      .then((response) => response.json())
      .then((response) => {
        this.courses = response.data;
        this.cards = response.data;
      });
  },
};
</script>
<style scoped>
.articles-section-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1400px !important;
  width: 100% !important;
}

.articles-section-content {
  width: 100% !important;
}

.articles-section-cards-container {
  width: 100% !important;
}

@media only screen and (width <= 800px) {
  .articles-section-cards-container {
    align-items: flex-start !important;
  }
}
</style>
