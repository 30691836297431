<template>
  <v-layout class="admin-panel">
    <main>
      <header class="d-flex align-center">
        <img
          src="@/assets/images/logo.svg"
          alt="logo"
          width="234px"
          style="cursor: pointer"
          @click="goHome()"
        />
        <div class="navigation-bar-avatar-container d-flex align-center">
          <v-btn
            variant="text"
            icon="mdi-logout"
            color="#8C8C8C"
            size="48px"
            @click="logout()"
          />
        </div>
      </header>
      <div class="main-container d-flex">
        <div class="side-navigation-bar d-flex flex-column">
          <div class="user-info-container d-flex align-center">
            <v-avatar
              image="https://t4.ftcdn.net/jpg/05/49/98/39/360_F_549983970_bRCkYfk0P6PP5fKbMhZMIb07mCJ6esXL.jpg"
              size="54"
            ></v-avatar>
            <div class="user-info-text">
              <h2>{{ user.firstname }} {{ user.lastname }}</h2>
              <p>{{ user.role.name }}</p>
            </div>
          </div>
          <v-divider color="#F0F0F0" thickness="1"></v-divider>
          <v-navigation-drawer width="500" permanent>
            <v-list>
              <div v-for="(item, index) in sideNavbarItems" :key="index">
                <v-list-group v-if="item?.sections" :title="item.title">
                  <template #activator="{ props }">
                    <v-list-item
                      v-bind="props"
                      :prepend-icon="item.prependIcon"
                      :title="item.title"
                      height="56px"
                      color="primary"
                    ></v-list-item>
                  </template>
                  <v-list-item
                    v-for="(section, sectionIndex) in item.sections"
                    :key="sectionIndex"
                    :title="section.title"
                    :value="section.title + Math.random()"
                    height="56px"
                    color="primary"
                    @click="handleNavbarItemClick(section.route)"
                  >
                  </v-list-item>
                </v-list-group>
                <v-list-item
                  v-else
                  :prepend-icon="item.prependIcon"
                  :title="item.title"
                  :value="item.title"
                  height="56px"
                  color="primary"
                  @click="handleNavbarItemClick(item.route)"
                >
                </v-list-item>
              </div>
            </v-list>
          </v-navigation-drawer>
          <footer>
            <p>{{ new Date().getFullYear() }} © SIA Zantix</p>
          </footer>
        </div>
        <div class="content-wrapper">
          <slot />
        </div>
      </div>
    </main>
  </v-layout>
</template>

<script>
import { UserData } from "@/storage/user";

export default {
  name: "AdminLayout",
  data: () => ({
    user: UserData.User,
    sideNavbarItems: [
      {
        title: "Galvenais panelis",
        height: "56px",
        prependIcon: "mdi-view-dashboard",
        route: "/admin",
      },
      {
        title: "Lietotāji",
        height: "56px",
        prependIcon: "mdi-account-multiple",
        sections: [
          {
            title: "Saraksts",
            route: "/admin/users/list",
          },
          {
            title: "Izveidot",
            route: "/admin/users/create",
          },
        ],
      },
      {
        title: "Kursi",
        height: "56px",
        prependIcon: "mdi-school",
        sections: [
          {
            title: "Saraksts",
            route: "/admin/courses/list",
          },
          {
            title: "Izveidot",
            route: "/admin/courses/create",
          },
        ],
      },
      {
        title: "Testi",
        height: "56px",
        prependIcon: "mdi-format-list-bulleted-square",
        sections: [
          {
            title: "Saraksts",
            route: "/admin/tests/list",
          },
          {
            title: "Izveidot",
            route: "/admin/tests/create",
          },
        ],
      },
      {
        title: "Pasūtījumi",
        height: "56px",
        prependIcon: "mdi-application-edit-outline",
        sections: [
          {
            title: "Kursu pasūtījumu saraksts",
            route: "/admin/user_courses/order_list",
          },
          {
            title: "Apliecību pasūtījumu saraksts",
            route: "/admin/user_courses/license_order_list",
          },
          {
            title: "Apliecību piegādes saraksts",
            route: "/admin/user_courses/license_delivery_list",
          },
        ],
      },
      {
        title: "Uzņēmumi",
        height: "56px",
        prependIcon: "mdi-domain",
        sections: [
          {
            title: "Saraksts",
            route: "/admin/companies/list",
          },
          {
            title: "Izveidot",
            route: "/admin/companies/create",
          },
        ],
      },
      {
        title: "Notikumi",
        height: "56px",
        prependIcon: "mdi-newspaper-variant-outline",
        sections: [
          {
            title: "Saraksts",
            route: "/admin/articles/list",
          },
          {
            title: "Izveidot",
            route: "/admin/articles/create",
          },
        ],
      },
    ],
  }),
  methods: {
    logout() {
      this.$router.push("/logout");
    },
    goHome() {
      this.$router.push("/");
    },
    handleNavbarItemClick(route) {
      this.$router.push({ path: route });
    },
  },
};
</script>

<style scoped>
header {
  width: 100vw;
  height: 75px;
  background-color: #fff;
  padding: 0 35px;
}

.v-navigation-drawer {
  position: static !important;
  width: 100% !important;
  background-color: #262626;
  color: #fff;
}

.navbar-breadcrumbs > .v-breadcrumbs__item {
  color: #595959;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
}

.navigation-bar-avatar-container {
  gap: 25px;
  margin-left: auto;
}

.main-container {
  height: calc(100vh - 75px);
}

.side-navigation-bar {
  flex-shrink: 0;
  background-color: #262626;
  color: #fff;
}

.v-list {
  padding: 0;
}

.user-info-container {
  height: fit-content;
  padding: 12px 0 12px 20px;
}

.user-info-text {
  padding: 0 20px;
}

.user-info-text > h2 {
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
}

.user-info-text > p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

footer {
  width: 100%;
  text-align: center;
  margin-top: auto;
  padding: 15px 54px;
}

.content-wrapper {
  padding: 20px 35px;
  overflow-y: scroll;
  flex-grow: 1;
}
</style>
