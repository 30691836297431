export default class GeneralStore {
  private static store: any = {};
  public static initalize(_context: any) {
    this.store = _context;
  }

  public static setValue(location: string[], value: any) {
    this.store.commit("setData", { location, value });
  }

  public static getValue(location: string[]): any {
    return this.store.getters.field({ location });
  }
}
