<template>
  <v-card class="section-container">
    <h2 class="title">Vispārīgā informācija</h2>
    <v-alert v-if="profile.isUpdated" type="success" class="mb-5">
      {{ profile.message }}
    </v-alert>
    <v-alert v-if="profile.isError" type="error" class="mb-5">
      {{ profile.message }}
    </v-alert>
    <v-form ref="form" @submit.prevent>
      <div class="container">
        <div class="inputs">
          <div class="inputs-row">
            <v-text-field
              v-model="user.firstname"
              :rules="rules.firstname"
              variant="underlined"
              color="primary"
              label="Vārds"
              autocomplete="off"
              required
              class="mb-3 input"
            ></v-text-field>
            <v-text-field
              v-model="user.lastname"
              :rules="rules.lastname"
              variant="underlined"
              color="primary"
              label="Uzvārds"
              autocomplete="off"
              required
              class="mb-3 input"
            ></v-text-field>
          </div>
          <div class="inputs-row">
            <v-text-field
              v-model="user.email"
              :rules="rules.email"
              variant="underlined"
              color="primary"
              label="E-pasts"
              autocomplete="email"
              required
              class="mb-3 input"
            ></v-text-field>
            <v-text-field
              v-model="user.phone_number"
              :rules="rules.phoneNumber"
              variant="underlined"
              color="primary"
              label="Telefona numurs"
              autocomplete="off"
              class="mb-3 input"
            ></v-text-field>
          </div>
          <div class="inputs-row">
            <v-text-field
              v-model="user.personal_code"
              :rules="rules.personalCode"
              variant="underlined"
              color="primary"
              label="Personas kods"
              autocomplete="off"
              class="mb-3 input"
            ></v-text-field>
          </div>
        </div>
        <div class="profile-avatar">
          <v-img
            class="avatar"
            :width="250"
            :height="250"
            aspect-ratio="1/1"
            :src="avatarPreview"
            cover
          ></v-img>
          <v-file-input
            v-model="avatar"
            :rules="rules.avatar"
            accept="image/png, image/jpeg"
            prepend-icon="mdi-camera"
            label="Profile bilde"
            variant="underlined"
            color="primary"
            @change="avatarUpload"
          />
        </div>
      </div>
      <div class="confirm-container">
        <v-btn
          color="primary"
          class="text-lowercase"
          :disabled="isLoading"
          :loading="isLoading"
          @click="submitProfileUpdate()"
          >Saglabāt</v-btn
        >
        <v-btn
          color="primary"
          variant="outlined"
          class="text-lowercase"
          @click="revertData()"
          >Atmest izmaiņas</v-btn
        >
      </div>
    </v-form>
  </v-card>
</template>
<script>
import { UserData } from "@/storage/user";
import { ruleSet, ruleSetGen } from "@/helpers/rules";
import { ProfileAPI } from "@/api/apis/profileActions";

export default {
  name: "LoginView",
  data: () => {
    return {
      isLoading: null,
      avatarPreview: null,
      avatar: null,
      user: UserData.User,
      rules: {
        avatar: ruleSet.fileType,
        firstname: ruleSetGen.text("Lūdzu ievadiet derīgu vārdu", true, 3),
        lastname: ruleSetGen.text("Lūdzu ievadiet derīgu uzvārdu", true, 3),
        email: ruleSet.email,
        phoneNumber: ruleSetGen.phoneNumber(undefined, false),
        personalCode: ruleSetGen.persCode(undefined, false),
      },
      profile: {
        isUpdated: false,
        isError: false,
        message: null,
      },
    };
  },
  mounted() {
    this.avatarPreview = this.user.avatar;
  },
  methods: {
    revertData() {
      this.avatar = null;
      this.avatarPreview = UserData.Avatar;
      this.user = {
        avatar: UserData.Avatar,
        firstname: UserData.Firstname,
        lastname: UserData.Lastname,
        email: UserData.Email,
        phone_number: UserData.PhoneNumber,
        personal_code: UserData.PersonalCode,
        address: "",
      };
    },
    avatarUpload() {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", (file) => {
        this.avatarPreview = file.target.result;
      });
      fileReader.readAsDataURL(this.avatar[0]);
      this.user.avatar = this.avatar[0];
    },
    async submitProfileUpdate() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) return;
      this.isLoading = true;

      ProfileAPI.updateProfile(this.user)
        .then((response) => {
          this.profile.isError = false;
          this.profile.isUpdated = true;
          this.profile.message = response.message;
          UserData.setUser(response.user);
        })
        .catch((response) => {
          this.profile.isUpdated = false;
          this.profile.isError = true;
          this.profile.message = response.message;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.section-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  border-radius: 15px;
  padding: 20px 25px;
}

.title {
  margin-bottom: 20px;
}

.container {
  display: flex;
  gap: 20px;
}

.inputs {
  width: 100%;
}

.inputs-row {
  display: flex;
  gap: 20px;
}

.input {
  width: 50%;
}

.inputs-row:last-child {
  width: calc(50% - 10px);
}

.profile-avatar {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: fit-content;
}

.avatar {
  border-radius: 15px;
}

.confirm-container {
  display: flex;
  gap: 10px;
}

@media only screen and (width <= 1400px) {
  .inputs-row {
    flex-direction: column;
    width: 100%;
    gap: 0;
  }

  .input {
    width: initial;
  }

  .inputs-row:last-child {
    width: initial;
  }
}

@media only screen and (width <= 1000px) {
  .container {
    flex-direction: column-reverse;
  }
}
</style>
